import React from 'react'
import { observer } from 'mobx-react-lite';
import { PieDatum } from '@nivo/pie';
import { firstBy } from 'thenby';

import { useStore } from '../../Data/Context/StoreContext';
import GenericNavigationPage from '../../General/Pages/GenericNavigationPage'
import Pie from '../Components/Pie';

interface Props {

}

const TopCategoriesPage = observer((props: Props) => {
    const store = useStore();

    const data: PieDatum[] = [];
    store.data.categories.all.forEach(category => {
        const value = store.calculator.getAmountForCategory(category);
        if (value < 0)
            data.push({ id: category.text, label: category.text, value: Math.abs(value) });
    });
    data.sort(firstBy(x => x.value, 'desc'));
    data.splice(5);

    return (
        <GenericNavigationPage title='Top Categories'>
            <Pie data={data} />
        </GenericNavigationPage>
    )
})

export default TopCategoriesPage
