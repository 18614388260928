import React from 'react'
import { IonList, IonItem, IonIcon, IonLabel } from '@ionic/react'
import { pieChartOutline } from 'ionicons/icons'
import GenericNavigationPage from '../../General/Pages/GenericNavigationPage'


const OverviewPage = () => {
    return (
        <GenericNavigationPage title='Stats'>
            <IonList>

                <IonItem button detail routerLink='/stats/top-categories'>
                    <IonIcon icon={pieChartOutline} slot="start" />
                    <IonLabel>
                        Top Categories
                    </IonLabel>
                </IonItem>

            </IonList>
        </GenericNavigationPage>
    )
}

export default OverviewPage
