import React, { useEffect, useState } from 'react'
import { ResponsivePieCanvas, PieDatum } from '@nivo/pie';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../Data/Context/StoreContext';

interface Props {
    data: PieDatum[];
}

const getCssVariable = (name: string) =>
    getComputedStyle(document.documentElement)
        .getPropertyValue(name);

const Pie = observer((props: Props) => {

    const store = useStore();
    const [angleMode, setAngleMode] = useState<'r' | 'v' | 'h'>('r');

    useEffect(() => {
        const listener = () => {
            const ratio = window.innerWidth / window.innerHeight;
            if (ratio < 0.7) {
                // Vertical
                setAngleMode('v');
            } else if (ratio > 1.8) {
                // Horizontal
                setAngleMode('h');
            } else {
                // Round
                setAngleMode('r');
            }
        };
        listener();

        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, []);

    return (
        <div style={{ height: 'calc(100vh - 120px)' }}>
            <ResponsivePieCanvas
                data={props.data}
                margin={{ top: 20, bottom: 20, left: 100, right: 100 }}
                pixelRatio={1}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={8}
                colors={{ scheme: 'paired' }}
                borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
                startAngle={angleMode === 'r' || angleMode === 'v' ? 0 : 90}
                endAngle={angleMode === 'r' ? 360 : angleMode === 'v' ? 180 : 270}
                sortByValue={true}
                radialLabelsSkipAngle={5}
                radialLabelsTextXOffset={4}
                radialLabelsTextColor={getCssVariable('--ion-color-light')}
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={8}
                radialLabelsLinkHorizontalLength={15}
                radialLabelsLinkStrokeWidth={2}
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabel={(e) => { return store.formatter.currency(e.value) }}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        itemWidth: 80,
                        itemHeight: 14,
                        itemsSpacing: 5,
                        symbolSize: 14,
                        symbolShape: 'circle',
                        itemTextColor: getCssVariable('--ion-color-medium'),
                        
                    }
                ]}
            />
        </div>
    )
})

export default Pie
