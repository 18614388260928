import React from 'react'
import GenericNavigationPage from '../../General/Pages/GenericNavigationPage'
import I18nForm from '../Components/I18nForm'

interface Props {
    
}

const I18nPage = (props: Props) => {
    return (
        <GenericNavigationPage title='Language &amp; Units'>
            <I18nForm />
        </GenericNavigationPage>
    )
}

export default I18nPage
