
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../Data/Context/StoreContext'
import { IonRow, IonCol, IonGrid, IonIcon, IonText, IonChip, IonLabel } from '@ionic/react';
import { AllIcons, TIcon } from '../../Data/Constants/Icons';

interface Props {

}

const Bill = observer((props: Props) => {
    const store = useStore();

    const budget = store.calculator.availableBudget;
    const totalIncome = store.calculator.totalIncome;
    const totalExpenses = store.calculator.totalExpenses;
    const total = budget + store.calculator.total;

    return (
        <div className='ion-margin-top'>

            <IonGrid>
                <BillRow icon='wallet' label="Budget" amount={budget} divider />

                {store.view.currentMonth.transactions.all.map((transaction, i) => (
                    <BillRow
                        key={transaction.uid}
                        label={transaction.category?.text ?? '-'}
                        subLabel={transaction.text}
                        tags={transaction.tags}
                        amount={transaction.amount}
                        date={transaction.date}
                        icon={transaction.category?.icon}
                        divider={store.view.currentMonth.transactions.all.length === i + 1}
                    />
                ))}

                <BillRow icon='add' label="Total Income" amount={totalIncome} />
                <BillRow icon='remove' label="Total Expenses" amount={totalExpenses} />
                <BillRow icon='reorderTwo' label="Total" amount={total} />

            </IonGrid>


        </div>
    )
})

interface BillRowProps {
    icon?: TIcon;
    label: string;
    subLabel?: string;
    tags?: readonly string[];
    date?: Date;
    amount: number;
    divider?: boolean;
}

const BillRow: React.FC<BillRowProps> = ({ label, subLabel, tags, date, amount, icon, divider }) => {
    const store = useStore();

    const amountColor = amount < 0 ? 'negative' : amount > 0 ? 'positive' : '';

    return (
        <IonRow className={'ion-align-items-center ' + (divider ? 'divider-row' : 'nondivider-row')}>
            <IonCol sizeXs='0' sizeMd='1' sizeLg='2'></IonCol>
            <IonCol sizeXs='1' className='ion-text-right'>
                {icon && <IonIcon size='large' icon={AllIcons[icon].data} />}
            </IonCol>
            <IonCol className='ion-margin-start'>
                {label}
                {subLabel && <><br /><IonText color="light">{subLabel}</IonText></>}
                {tags && tags.length > 0 && (
                    <>
                        <br />
                        {tags.map(tag => (
                            <IonChip key={tag}  color='tertiary'>
                                <IonLabel>{tag.toUpperCase()}</IonLabel>
                            </IonChip>
                        ))}
                    </>
                )}
            </IonCol>
            
            <IonCol sizeXs='2' className='ion-text-right ion-margin-end ion-text-nowrap'>
                <IonText color={amountColor}>
                    {store.formatter.currency(amount)}
                </IonText>
                
                {date && (
                    <>
                        <br />
                        <IonText color="light">{store.formatter.dayMonthAndTime(date)}</IonText>
                    </>
                )}
            </IonCol>
            <IonCol sizeXs='0' sizeMd='1' sizeLg='2'></IonCol>
        </IonRow>
    );
}

export default Bill