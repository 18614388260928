import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import CategoryGrid from '../Components/CategoryGrid';
import RemainingPanel from '../Components/RemainingPanel';
import { saveOutline } from 'ionicons/icons';
import { useStoreManager, useStore } from '../../Data/Context/StoreContext';
import GenericNavigationPage from '../../General/Pages/GenericNavigationPage';
import SyncTrigger from '../../Synchronization/Components/SyncTrigger';


const BudgetPage: React.FC = () => {
    const store = useStore();
    const storeManager = useStoreManager();

    const handleSave = () => {
        storeManager.saveStore(store);
    }

    return (
        <GenericNavigationPage
            title='Budget'
            titleEndButton={
                <>
                <IonButton onClick={handleSave}>
                    <IonIcon icon={saveOutline}></IonIcon>
                </IonButton>
                <SyncTrigger />
                </>
            }
        >

            <RemainingPanel />
            <CategoryGrid />
        </GenericNavigationPage>
    );
};

export default BudgetPage;
