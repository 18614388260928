import { Store } from "../Store/Store";
import { DropboxSyncClient } from "../../Synchronization/Services/DropboxSyncClient";
import { EncryptionService } from "../../Synchronization/Services/EncryptionService";
import { observable } from "mobx";

// bTixbJ0XanTf2iK%

const CONFIG_FILENAME = 'cfg.txt';
const CATEGORIES_FILENAME = 'cats.txt';
const MONTHS_FILENAME = 'mons.txt';
const TRANSACTIONS_FILENAME_PREFIX = 'trx_';
const TRANSACTIONS_FILENAME_SUFFIX = '.txt';


export class Sync {

    @observable
    syncStatusMessage: string = '';

    @observable
    abortSync: boolean = false;

    @observable
    abortMessage: string = '';


    constructor(private store: Store) {
    }

    private _syncClient?: DropboxSyncClient;
    private _encryption?: EncryptionService;

    private get syncClient() {
        if (!this._syncClient) {
            this._syncClient = new DropboxSyncClient(this.store);
        }
        return this._syncClient;
    }

    private get encryption() {
        if (!this._encryption) {
            this._encryption = new EncryptionService(this.store);
        }
        return this._encryption;
    }


    async beginSignIn() {
        await this.syncClient.startSignIn();
    }

    async completeSignIn(code: string) {
        await this.syncClient.completeSignIn(code);
    }

    get isAuthPresent() {
        return this.syncClient.isAuthenticated;
    }

    async isAuthValid() {
        return await this.syncClient.testToken();
    }

    async isPasswordValid() {
        try {
            await this.downloadAndDecrypt(
                'Categories',
                'cats.txt',
                (data: any) => this.store.serializer.deserializeCategories(data)
            );
            return true;

        } catch (err) {
            console.error('PW CHECK', err);
        }
        return false;
    }

    async doSync() {
        this.abortSync = false;
        this.abortMessage = '';

        try {
            await this.syncConfig();
            await this.syncCategories();
            await this.syncMonths();
        } catch (err) {
            this.abortSync = true;
            this.abortMessage = err.message;
        }

        return true;
    }

    private async syncConfig() {
        const remoteConfig = await this.downloadAndDecrypt(
            'Config',
            CONFIG_FILENAME,
            (data: any) => this.store.serializer.deserializeConfig(data)
        );

        if (this.abortSync) return false;

        let hasChanged = true;
        if (remoteConfig) {
            this.syncStatusMessage = 'Synchronizing Config';
            hasChanged = this.store.config.syncFromRemote(remoteConfig);
        }

        if (hasChanged) {
            await this.encryptAndUpload(
                'Config',
                CONFIG_FILENAME,
                this.store.config,
                (data) => this.store.serializer.serializeConfig(data)
            );
            this.syncStatusMessage = 'Config updated';
        } else {
            this.syncStatusMessage = 'Config not changed';
        }
    }

    private async syncCategories() {
        const remoteCategories = await this.downloadAndDecrypt(
            'Categories',
            CATEGORIES_FILENAME,
            (data: any) => this.store.serializer.deserializeCategories(data)
        );

        if (this.abortSync) return false;

        let hasChanged = true;
        if (remoteCategories) {
            this.syncStatusMessage = 'Synchronizing Categories';
            hasChanged = this.store.data.categories.syncFromRemote(remoteCategories);
        }

        if (hasChanged) {
            await this.encryptAndUpload(
                'Categories',
                CATEGORIES_FILENAME,
                this.store.data.categories,
                (data) => this.store.serializer.serializeCategories(data)
            );
            this.syncStatusMessage = 'Categories updated';
        } else {
            this.syncStatusMessage = 'Categories not changed';
        }
    }

    private async syncMonths() {
        const remoteMonths = await this.downloadAndDecrypt(
            'Months',
            MONTHS_FILENAME,
            (data: any) => this.store.serializer.deserializeMonths(data)
        );

        if (this.abortSync) return false;

        let hasChanged = true;
        let needSyncAllTransactions = true;
        let transactionsRequiredToSync: number[] = [];

        if (remoteMonths) {
            this.syncStatusMessage = 'Synchronizing Months';
            hasChanged = this.store.data.months.syncFromRemote(remoteMonths);

            needSyncAllTransactions = false;
            transactionsRequiredToSync = this.store.data.months.getTransactionsRequiredToSync(remoteMonths);
        }

        if (hasChanged) {
            await this.encryptAndUpload(
                'Months',
                MONTHS_FILENAME,
                this.store.data.months,
                (data) => this.store.serializer.serializeMonths(data)
            );
            this.syncStatusMessage = 'Months updated';
        } else {
            this.syncStatusMessage = 'Months not changed';
        }

        console.log('TRANSACTIONS REQUIRED TO SYNC:', transactionsRequiredToSync);
        console.log('ALL TRANSACTIONS REQUIRED TO SYNC?', needSyncAllTransactions);

        if (needSyncAllTransactions) {
            for (let i = 0; i < this.store.data.months.all.length; i++) {
                const month = this.store.data.months.all[i];
                await this.syncTransactions(month.date.getTime());
            }
        }

        if (transactionsRequiredToSync.length > 0) {
            for (let i = 0; i < transactionsRequiredToSync.length; i++) {
                const timestamp = transactionsRequiredToSync[i];
                await this.syncTransactions(timestamp);
            }
        }
    }

    private async syncTransactions(timestamp: number) {

        const datedisp = this.store.formatter.monthAndYear(new Date(timestamp));
        const remoteTransactions = await this.downloadAndDecrypt(
            `Transactions ${datedisp}`,
            `${TRANSACTIONS_FILENAME_PREFIX}${timestamp}${TRANSACTIONS_FILENAME_SUFFIX}`,
            (data: any) => this.store.serializer.deserializeTransactions(data)
        );

        let hasChanged = true;
        if (remoteTransactions) {
            this.syncStatusMessage = `Synchronizing Transactions ${datedisp}`;

            hasChanged = this.store.data
                .months.ensure(new Date(timestamp))
                .transactions.syncFromRemote(remoteTransactions);
        }

        if (hasChanged) {
            await this.encryptAndUpload(
                `Transactions ${datedisp}`,
                `${TRANSACTIONS_FILENAME_PREFIX}${timestamp}${TRANSACTIONS_FILENAME_SUFFIX}`,
                this.store.data
                    .months.ensure(new Date(timestamp))
                    .transactions,
                (data) => this.store.serializer.serializeTransactions(data)
            );
            this.syncStatusMessage = `Transactions ${datedisp} updated`;
        } else {
            this.syncStatusMessage = `Transactions ${datedisp} not changed`;
        }
    }


    private async downloadAndDecrypt<T>(title: string, filename: string, deserializeCb: (obj: any) => T | undefined) {
        // Download remote file
        let fileContent = '';
        try {
            this.syncStatusMessage = `Downloading ${title}`;
            fileContent = await this.syncClient.getFile(`/${filename}`);
        } catch (err) {
            console.error(`Cannot get remote ${title}:`, err.message);
            if (err.message !== 'not-found') {
                this.abortSync = true; // other errors -> cancel sync
                this.abortMessage = `Unknown error while trying to download ${title}`;
                console.error(`Cannot download ${title}`, err);
                throw new Error(`Unknown error while trying to download ${title}`);
            }
            return undefined;
        }

        // Decrypt and deserialize
        if (fileContent) {
            try {
                this.syncStatusMessage = `Decrypting ${title}`;
                const decrypted = this.encryption.decryptObject(fileContent);
                console.log("FROM SERVER", decrypted);
                const deserialized = deserializeCb(decrypted);
                console.log(`${title} on server`, deserialized);
                return deserialized;
            } catch (err) {
                this.abortSync = true;
                this.abortMessage = `Cannot decrypt, wrong password?`;
                console.error(`Cannot deserialize ${title}`, err);
                throw new Error(this.abortMessage);
            }
        }
    }

    private async encryptAndUpload<T>(title: string, filename: string, data: T, serializeCb: (obj: T) => any) {
        this.syncStatusMessage = `Encrypting ${title}`;
        const serialized = serializeCb(data);
        const encrypted = this.encryption.encryptObject(serialized);

        this.syncStatusMessage = `Uploading ${title}`;
        await this.syncClient.setFile(`/${filename}`, encrypted);
    }



}
