import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonItemDivider } from '@ionic/react'
import { SelectChangeEventDetail } from '@ionic/core';
import { useStore } from '../../Data/Context/StoreContext'
import { Countries } from '../../Data/Constants/Countries'

interface Props {

}

const I18nForm = observer(() => {
    const store = useStore();

    const [selectedRegion, setSelectedRegion] = useState(() =>
        Countries.find(x => x.language === store.config.locale)?.region ?? ''
    );

    const regions = Countries
        .map(x => x.region)
        .filter((x, i, arr) => arr.indexOf(x) === i);

    const countries = Countries
        .filter(x => x.region === selectedRegion);

    const selectedCountry = store.config.locale;

    console.log(selectedRegion, Countries.find(x => x.language === store.config.locale), store.config.locale);

    const handleChangeRegion = (e: CustomEvent<SelectChangeEventDetail>) => {
        setSelectedRegion(e.detail.value);
    }

    const handleChangeCountry = (e: CustomEvent<SelectChangeEventDetail>) => {
        store.config.locale = e.detail.value;

        const country = Countries.find(x => x.language === e.detail.value);
        if (country)
            store.config.currency = country.currency;
    }

    return (
        <IonList>
            <IonItem>
                <IonLabel position='floating'>Region</IonLabel>
                <IonSelect value={selectedRegion} onIonChange={handleChangeRegion}>
                    {regions.map(region => <IonSelectOption key={region} value={region}>{region}</IonSelectOption>)}
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel position='floating'>Country</IonLabel>
                <IonSelect value={selectedCountry} onIonChange={handleChangeCountry}>
                    {countries.map(country => <IonSelectOption key={country.name} value={country.language}>{country.name}</IonSelectOption>)}
                </IonSelect>
            </IonItem>
            <IonItemDivider />
            <IonItem>
                <IonLabel position='stacked'>Currency</IonLabel>
                {store.config.currency}
            </IonItem>
            <IonItem>
                <IonLabel position='stacked'>Locale</IonLabel>
                {store.config.locale}
            </IonItem>
        </IonList>
    )
})

export default I18nForm
