
import React from 'react'
import { IonLoading } from '@ionic/react'

interface Props {

}

const LoadingPage = (props: Props) => {
    return (
        <IonLoading
            isOpen={true}
            message={'Please wait...'}
        />
    )
}

export default LoadingPage

