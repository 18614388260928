import React from 'react'
import { IonPopover, IonList, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../Data/Context/StoreContext';
import CurrencyBox from '../../General/Components/CurrencyBox';

interface Props {
    state: TPopoverState;
    onClose(): void;
}

export type TPopoverState = { open: boolean; event: any };

const MonthPicker: React.FC<Props> = observer((props) => {

    const store = useStore();
    const monthNames = store.formatter.monthNames();
    const years = store.formatter.years();

    const monthNumber = store.view.currentMonth.date.getMonth() + 1;
    const yearNumber = store.view.currentMonth.date.getFullYear();
    console.log('MONTH', monthNumber, 'YEAR', yearNumber);

    const handleChangeMonth = (val: number) => {
        store.view.ensureAndSelectMonth(val - 1, yearNumber);
    }
    const handleChangeYear = (val: number) => {
        store.view.ensureAndSelectMonth(monthNumber - 1, val);
    }

    return (
        <IonPopover isOpen={props.state.open} event={props.state.event} onDidDismiss={props.onClose}>
            <IonList>
                <IonItem>
                    <IonLabel>Month</IonLabel>
                    <IonSelect value={monthNumber} onIonChange={(e) => handleChangeMonth(e.detail.value)}>

                        {monthNames.map((name, idx) =>
                            <IonSelectOption key={idx} value={(idx + 1)}>{name}</IonSelectOption>
                        )}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel>Year</IonLabel>
                    <IonSelect value={yearNumber} onIonChange={(e) => handleChangeYear(e.detail.value)}>
                        {years.map(year =>
                            <IonSelectOption key={year} value={year}>{year}</IonSelectOption>
                        )}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Budget available</IonLabel>
                    <CurrencyBox model={store.view.currentMonth} property='availableBudget' placeholder='Use default Amount'  />
                    {/* <IonInput type="number" placeholder='Use default Amount' value={1234.56} step="0.01" /> */}
                </IonItem>
            </IonList>
        </IonPopover>
    )
})

export default MonthPicker
