import { Store } from "../Store/Store";
import { IDataProvider } from "../Providers/IDataProvider";
import { LocalStorageDataProvider } from "../Providers/LocalStorageProvider";
import { Transactions } from "../Model/Transactions";
import { deserialize } from "serializr";

export class Loader {

    constructor(private store: Store) {
    }

    dataProvider: IDataProvider = new LocalStorageDataProvider();


    transactions(date: Date): Transactions {
        console.log('Loading transactions', date);
        const timestamp = date.getTime();
        const data = this.dataProvider.loadTransactions(timestamp);
        const existing = this.deserializeTransactions(data);
        if (existing) return existing;
        return new Transactions();
    }

    private deserializeTransactions(serialized: any) {
        if (!serialized) return undefined;
        const data = deserialize(Transactions, serialized);
        return data ?? undefined;
    }

}
