import React from 'react'
import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent } from '@ionic/react'

interface Props {
    title: string;

    titleEndButton?: JSX.Element;
}

const GenericNavigationPage: React.FC<Props> = (props) => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>{props.title}</IonTitle>

                    {props.titleEndButton && (
                        <IonButtons slot="end">
                            {props.titleEndButton}
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton />
                        </IonButtons>
                        <IonTitle size="large">{props.title}</IonTitle>

                        {props.titleEndButton && (
                            <IonButtons slot="end">
                                {props.titleEndButton}
                            </IonButtons>
                        )}
                    </IonToolbar>
                </IonHeader>

                {props.children}

            </IonContent>
        </IonPage>
    )
}

export default GenericNavigationPage
