import React from 'react'

interface Props {
    estimatedHeight?: number;
}

const Center: React.FC<Props> = (props) => {
    const height = props.estimatedHeight ?? 100;

    return (
        <div style={{ marginTop: `calc(50vh - ${height}px)` }} className="ion-text-center">
            {props.children}
        </div>
    );
}

export default Center
