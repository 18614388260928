
export function numberInputParser(input: string | null | undefined) {
    if (typeof input === 'undefined' || input === null || input.length === 0)
        return undefined;

    const lastDot = input.lastIndexOf('.');
    const lastComma = input.lastIndexOf(',');
    const lastSeparator = Math.max(lastDot, lastComma);

    if (lastSeparator >= 0) {
        const start = input
            .substr(0, lastSeparator)
            .replace(/\./g, '')
            .replace(/,/g, '');
        const end = input.substr(lastSeparator + 1);
        return parseFloat(`${start}.${end}`);
    }
    return parseFloat(input);
}
