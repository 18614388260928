import React, { useState, useEffect } from 'react'

import GenericNavigationPage from '../../General/Pages/GenericNavigationPage'
import { IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonButton, IonIcon, IonList, IonItem, IonListHeader, IonLabel, IonToggle, IonSpinner } from '@ionic/react'
import { logoDropbox, checkmarkOutline, closeOutline, refreshOutline, helpOutline } from 'ionicons/icons'
import { useStore } from '../../Data/Context/StoreContext'
import TextBox from '../../General/Components/TextBox'

interface Props {

}

const SyncSetupPage = () => {
    const store = useStore();

    const [isChecking, setIsChecking] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isTokenOk, setIsTokenOk] = useState(false);
    const [isPasswordOk, setIsPasswordOk] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [reCheckCounter, setReCheckCounter] = useState(0);

    // Checklist
    useEffect(() => {
        (async () => {
            setIsChecking(true);
            setIsAuthenticated(false);
            setIsTokenOk(false);
            setIsPasswordOk(false);

            let valid = false;

            setIsAuthenticated(store.sync.isAuthPresent);
            if (store.sync.isAuthPresent) {
                const tokenOK = await store.sync.isAuthValid();
                setIsTokenOk(tokenOK);

                const passwordOk = await store.sync.isPasswordValid();
                setIsPasswordOk(passwordOk);

                valid = tokenOK && passwordOk;
            }

            if (!valid) store.syncConfig.enabled = false;
            setIsValid(valid);

            setIsChecking(false);
        })();

    }, [store, reCheckCounter]);

    return (
        <GenericNavigationPage title="Setup Synchronization">

            <IonGrid>

                <IonRow>
                    <IonCol offsetSm="2" sizeSm="8">
                        <IonCard>
                            <IonCardContent>
                                <p>
                                    Before you can enable the cloud synchronization, the checklist below has to be valid.
                                </p>
                                <IonList>
                                    <IonItem>
                                        <IonLabel>
                                            Enable Cloud Synchronization
                                        </IonLabel>
                                        <IonToggle
                                            checked={store.syncConfig.enabled}
                                            onIonChange={(e) => store.syncConfig.enabled = e.detail.checked}
                                            disabled={!isValid}
                                        />
                                    </IonItem>
                                </IonList>
                                <p>
                                    Your data is only saved in your device. To reduce risk of data loss, it is highly recommended to setup a synchronization with a cloud storage, or otherwise create regulary backups by exporting the data manually.<br />
                                    This app supports synchronizing data with your own dropbox account, data will be encrypted with AES256.
                                </p>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol offsetSm="2" sizeSm="8" className='ion-padding-start ion-padding-end'>
                        <IonListHeader>
                            <IonLabel>Checklist</IonLabel>
                        </IonListHeader>
                        <IonList>
                            <IonItem>
                                <IonIcon slot='start' icon={isAuthenticated ? checkmarkOutline : closeOutline} />
                                <IonLabel>Authenticated</IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonIcon slot='start' icon={isTokenOk ? checkmarkOutline : closeOutline} />
                                <IonLabel>Access OK</IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonIcon slot='start' icon={isPasswordOk ? checkmarkOutline : closeOutline} />
                                <IonLabel>Master Password valid</IonLabel>
                            </IonItem>
                            <IonItem>
                                { isChecking && <IonSpinner name="crescent" /> }
                                <IonButton slot='end' onClick={() => setReCheckCounter(x => x + 1)}>
                                    <IonIcon slot='start' icon={refreshOutline} />
                                    Check again
                                </IonButton>
                            </IonItem>
                        </IonList>

                    </IonCol>
                </IonRow>



                <IonRow>
                    <IonCol offsetSm="2" sizeSm="8">
                        <IonCard>
                            <IonCardContent>
                                <p>
                                    For the cloud synchronization you need a Dropbox account. Please create one before to continue.
                                    Then press the button to sign in and allow the app to store data in the cloud, the app will get only access to a specific folder.
                                    <br /><br />
                                </p>
                                <div className='ion-text-center'>
                                    <IonButton routerLink='/sync/auth/dropbox/start'>
                                        <IonIcon slot='start' icon={logoDropbox} />
                                        Sign in with Dropbox
                                    </IonButton>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol offsetSm="2" sizeSm="8">
                        <IonCard>
                            <IonCardContent>
                                <p>
                                    Your data will be encrypted with the master password.
                                    Please write it down and <strong>keep it in a safe place</strong>, data cannot be restored without the correct password!
                                    <br /><br />
                                    If you use the app on multiple devices, make sure to use the same dropbox account and the same password everywhere.
                                </p>
                                <IonList>
                                    <IonItem>
                                        <IonLabel position='floating'>
                                            Master Password
                                        </IonLabel>
                                        <TextBox model={store.syncConfig} property='masterPassword' />
                                    </IonItem>
                                    <IonItem color='warning'>
                                        Warning: By changing the password you can lose access to the cloud data.
                                        Please make sure all data is synchronized on all devices BEFORE changing the password.
                                        After the change you need to clean the cloud data. For that start by using the check again button in the checklist.
                                    </IonItem>
                                </IonList>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>



            </IonGrid>

        </GenericNavigationPage>
    )
}

export default SyncSetupPage
