import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { IonButton, IonIcon } from '@ionic/react'

import GenericNavigationPage from '../../General/Pages/GenericNavigationPage'
import { useStore } from '../../Data/Context/StoreContext'
import { DropboxSyncClient } from '../Services/DropboxSyncClient'
import Center from '../../General/Components/Center'
import { syncOutline } from 'ionicons/icons'


function useQuery() {
    return new URLSearchParams(useLocation().search);
}


interface Props {

}

const DropboxAuthRedirectPage = () => {

    const store = useStore();
    const history = useHistory();
    const query = useQuery();
    const [isCompleted, setIsCompleted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(() => query.get('error_description'));

    const code = query.get('code');
    const isError = !!errorMessage;

    useEffect(() => {
        if (!isError && code) {
            (async () => {
                // Blocking navigation
                const unblock = history.block();

                try {
                    const dbx = new DropboxSyncClient(store);
                    const complete = await dbx.completeSignIn(code);
                    setIsCompleted(complete);
                } catch (err) {
                    setErrorMessage(err.message);
                    console.error('Authentication failed', err);
                }

                unblock();
            })();
        }
    }, [store, history, isError, code]);



    const handleBackToSyncClick = () => {
        history.replace('/settings/sync');
    };

    return (
        <GenericNavigationPage title='Authentication'>

            <Center>

                {isError && (
                    <>
                        <p><strong>Authentication failed</strong></p>
                        <p>{errorMessage}</p>
                        <IonButton onClick={handleBackToSyncClick}>
                            <IonIcon slot='start' icon={syncOutline} />
                            Back to Sync Settings
                        </IonButton>
                    </>
                )}

                {isCompleted && (
                    <>
                        <p><strong>Authentication completed</strong></p>
                        <IonButton onClick={handleBackToSyncClick}>
                            <IonIcon slot='start' icon={syncOutline} />
                            Back to Sync Settings
                        </IonButton>
                    </>
                )}

                {!isError && !isCompleted && (

                    <div>
                        <div className="sk-folding-cube">
                            <div className="sk-cube1 sk-cube"></div>
                            <div className="sk-cube2 sk-cube"></div>
                            <div className="sk-cube4 sk-cube"></div>
                            <div className="sk-cube3 sk-cube"></div>
                        </div>

                        <p>Authenticating with Dropbox</p>
                        <p>Please wait...</p>
                    </div>
                )}

            </Center>

        </GenericNavigationPage>
    )
}

export default DropboxAuthRedirectPage
