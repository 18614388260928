import { observable } from "mobx";
import { findInstancesInObjectTree } from "../../General/Helpers/findInstancesInObjectTree";

export class _Dirty {

    @observable
    private _isDirty: boolean = false;

    get is(): boolean {
        if (this._isDirty) return true;

        // Children
        const children = this.getChildrenInObject(this.obj);
        return children.some(x => x._isDirty);
    }

    set() {
        this._isDirty = true;
    }

    resolve() {
        this._isDirty = false;

        // Children
        const children = this.getChildrenInObject(this.obj);
        return children.forEach(x => x._isDirty = false);
    }

    private getChildrenInObject(obj: any): _Dirty[] {


        return findInstancesInObjectTree(obj, _Dirty)
            .filter(x => x !== this);

        // if (!obj || obj === this) return [];

        // if (obj instanceof _Dirty) {
        //     return [obj];
        // }

        // if (Array.isArray(obj)) {
        //     const results: _Dirty[] = [];
        //     obj.forEach(item => {
        //         const res = this.getChildrenInObject(item);
        //         results.push(...res);
        //     });
        //     return results;
        // }

        // if (typeof obj === 'object') {
        //     const results: _Dirty[] = [];
        //     Object
        //         .keys(obj)
        //         .forEach(key => {
        //             const prop = obj[key];
        //             const res = this.getChildrenInObject(prop);
        //             results.push(...res);
        //         });
        //     return results;
        // }

        // return [];
    }

    constructor(private obj: any) {
    }


}
