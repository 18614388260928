import { IDataProvider } from "../Providers/IDataProvider";
import { LocalStorageDataProvider } from "../Providers/LocalStorageProvider";
import { Store, localStore } from "./Store";
import { deserialize, serialize } from "serializr";
import { Categories } from "../Model/Categories";
import { Months } from "../Model/Months";
import { Configuration } from "../Model/Configuration";
import { SyncConfig } from "../Model/SyncConfig";
import { Transactions } from "../Model/Transactions";

export class StoreManager {

    private dataProvider: IDataProvider;

    /**
     *
     */
    constructor() {
        this.dataProvider = new LocalStorageDataProvider();
    }

    async initStore() {
        const store = localStore;

        const config = await this.dataProvider.loadConfig();
        const deserializedConfig = store.serializer.deserializeConfig(config);
        if (deserializedConfig)
            store.config = deserializedConfig;

        const syncConfig = await this.dataProvider.loadSyncConfig();
        const deserializedSyncConfig = store.serializer.deserializeSyncConfig(syncConfig);
        if (deserializedSyncConfig)
            store.syncConfig = deserializedSyncConfig;

        const categories = await this.dataProvider.loadCategories();
        const deserializedCategories = store.serializer.deserializeCategories(categories);
        if (deserializedCategories)
            store.data.categories = deserializedCategories;

        const months = await this.dataProvider.loadMonths();
        const deserializedMonths = store.serializer.deserializeMonths(months);
        if (deserializedMonths)
            store.data.months = deserializedMonths;

        // Wait for change trackers to get active... (~100ms)
        await this.wait(500);

        return store;
    }

    private async wait(ms: number) {
        return new Promise(resolve => {
            setTimeout(() => resolve(), ms);
        });
    }

    private async loadStore() {
        const storedObj = await this.dataProvider.load();
        if (!storedObj) return undefined;

        return this.deserializeStore(storedObj);
    }


    async saveStore(store: Store) {

        await this.saveConfig(store);
        await this.saveSyncConfig(store);
        await this.saveCategories(store);
        await this.saveMonths(store);
        await this.saveTransactions(store);

        //const obj = this.serializeStore(store);
        //return await this.dataProvider.save(obj);

    }

    private async saveConfig(store: Store) {
        if (!store.config.dirty.is) return;
        const serialized = serialize(Configuration, store.config);
        this.dataProvider.saveConfig(serialized);
        store.config.dirty.resolve();
        console.log('Config saved');
    }

    private async saveSyncConfig(store: Store) {
        if (!store.syncConfig.dirty.is) return;
        const serialized = serialize(SyncConfig, store.syncConfig);
        this.dataProvider.saveSyncConfig(serialized);
        store.syncConfig.dirty.resolve();
        console.log('SyncConfig saved');
    }

    private async saveCategories(store: Store) {
        if (!store.data.categories.dirty.is) return;
        const serialized = serialize(Categories, store.data.categories);
        this.dataProvider.saveCategories(serialized);
        store.data.categories.dirty.resolve();
        console.log('Categories saved');
    }

    private async saveMonths(store: Store) {
        if (!store.data.months.dirty.is) return;
        const serialized = serialize(Months, store.data.months);
        this.dataProvider.saveMonths(serialized);
        store.data.months.dirty.resolve();
        console.log('Months saved');
    }

    private async saveTransactions(store: Store) {
        let changed = false;
        for (let key in store.data.loadedTransactions) {
            const transactions = store.data.loadedTransactions[key];
            if (!transactions.dirty.is) return;

            const serialized = serialize(Transactions, transactions);
            this.dataProvider.saveTransactions(+key, serialized);
            transactions.dirty.resolve();

            // Update index
            const month = store.data.months.ensure(new Date(+key));
            month.transactionsChanged = transactions.lastChanged.get();
            console.log('MONTH', month, month.transactionsChanged);

            changed = true;
        }

        if (changed) {
            console.log('Transactions saved');

            await this.saveMonths(store);
        }

    }

    serializeStore(store: Store) {
        return serialize(Store, store);
    }

    deserializeStore(serializedStore: any) {
        try {
            const store = deserialize(Store, serializedStore);
            if (!store) return undefined;
            console.log('Loaded', store);
            return store;
        } catch (err) {
            console.error('Failed deserializing store', err);
            return undefined;
        }
    }



}
