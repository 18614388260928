import { reaction, IReactionDisposer } from "mobx";
import { _LastChange } from "../Model/_LastChange";
import { _Dirty } from "../Model/_Dirty";

interface IChangeTrackingObject {
    unsubscriber?: IReactionDisposer;
    changeDetection(): any[];
    lastChanged: _LastChange;
    dirty: _Dirty;
}

export function changeTracker(object: IChangeTrackingObject) {
    setTimeout(() => {
        object.unsubscriber = reaction(
            () => object.changeDetection(),
            () => {
                object.lastChanged.now();
                object.dirty.set();
            }
        );
    }, 100);
}
