import React from 'react'
import { IonList, IonItem, IonIcon, IonLabel, IonItemDivider } from '@ionic/react'
import { calendarOutline, pricetagsOutline, thermometerOutline, syncOutline, exitOutline, helpOutline, cashOutline, informationOutline } from 'ionicons/icons'
import GenericNavigationPage from '../../General/Pages/GenericNavigationPage'

interface Props {

}

const OverviewPage = () => {
    return (
        <GenericNavigationPage title='Settings'>
            <IonList>

                <IonItem button detail routerLink='/settings/current-month'>
                    <IonIcon icon={calendarOutline} slot="start" />
                    <IonLabel>
                        Current Month
                    </IonLabel>
                </IonItem>

                <IonItem button detail routerLink='/settings/categories'>
                    <IonIcon icon={pricetagsOutline} slot="start" />
                    <IonLabel>
                        Categories
                    </IonLabel>
                </IonItem>

                <IonItem button detail routerLink='/settings/i18n'>
                    <IonIcon icon={thermometerOutline} slot="start" />
                    <IonLabel>
                        Language &amp; Units
                    </IonLabel>
                </IonItem>

                <IonItem button detail routerLink='/settings/sync'>
                    <IonIcon icon={syncOutline} slot="start" />
                    <IonLabel>
                        Synchronization
                    </IonLabel>
                </IonItem>

                <IonItem button detail routerLink='/settings/export'>
                    <IonIcon icon={exitOutline} slot="start" />
                    <IonLabel>
                        Export &amp; Import
                    </IonLabel>
                </IonItem>

                <IonItemDivider>
                    <IonLabel>
                        About
                    </IonLabel>
                </IonItemDivider>

                <IonItem button detail routerLink='/settings/help'>
                    <IonIcon icon={helpOutline} slot="start" />
                    <IonLabel>
                        Help
                    </IonLabel>
                </IonItem>

                <IonItem button detail routerLink='/settings/donate'>
                    <IonIcon icon={cashOutline} slot="start" />
                    <IonLabel>
                        Donate
                    </IonLabel>
                </IonItem>

                <IonItem button detail routerLink='/settings/about'>
                    <IonIcon icon={informationOutline} slot="start" />
                    <IonLabel>
                        About
                    </IonLabel>
                </IonItem>

            </IonList>
        </GenericNavigationPage>
    )
}

export default OverviewPage
