import { observable } from "mobx";
import { findInstancesInObjectTree } from "../../General/Helpers/findInstancesInObjectTree";
import { serializable } from "serializr";

export class _LastChange {

    @observable
    @serializable
    private _date: number = Date.now();

    /** Returns highest lastChange date in this object or children */
    get() {
        let max = this._date;

        // Children
        const children = this.getChildrenInObject(this.obj);
        children.forEach(x => {
            if (x._date > max)
                max = x._date;
        });
        return max;
    }

    /** Set lastChange date to now  */
    now() {
        this._date = Date.now();
    }

    
    private getChildrenInObject(obj: any): _LastChange[] {
        return findInstancesInObjectTree(obj, _LastChange)
            .filter(x => x !== this);
    }

    constructor(private obj: any) {
    }


}
