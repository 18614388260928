import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { IonList, IonItem, IonLabel, IonButton, IonAlert } from '@ionic/react';
import CategoryForm from '../Components/CategoryForm';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../Data/Context/StoreContext';
import GenericNavigationPage from '../../General/Pages/GenericNavigationPage';

interface Props extends RouteComponentProps<{ id: string }> {

}

const CategoryFormPage = observer((props: Props) => {
    const store = useStore();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const id = props.match.params.id;
    const category = store.data.categories.get(id);


    const handleDeleteClick = () => setShowDeleteConfirm(true);
    const handleDeleteCancel = () => setShowDeleteConfirm(false);
    const handleDeleteConfirm = () => {
        store.data.categories.remove(category);
        props.history.goBack();
    }

    return (

        <GenericNavigationPage
            title='Edit Category'
            titleEndButton={
                <IonButton fill='solid' color='danger' onClick={handleDeleteClick}>
                    Delete
                </IonButton>
            }
        >

            {category && <CategoryForm category={category} />}

            {!category && (
                <IonList>
                    <IonItem>
                        <IonLabel>Category not found</IonLabel>
                    </IonItem>
                </IonList>
            )}

            <IonAlert
                isOpen={showDeleteConfirm}
                onDidDismiss={handleDeleteCancel}
                header={'Warning'}
                message={'Remove this category?<p><small>Note: All transactions in this category will be removed!</small></p>'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel'
                    },
                    {
                        text: 'Ok',
                        handler: handleDeleteConfirm
                    }
                ]}
            />

        </GenericNavigationPage>
    )
})

export default CategoryFormPage
