import React, { useState } from 'react'
import { useStore } from '../../Data/Context/StoreContext'
import { observer } from 'mobx-react-lite';
import MonthPicker, { TPopoverState } from './MonthPicker';

interface Props {
}

const RemainingPanel = observer((props: Props) => {
    const store = useStore();
    const [popoverState, setPopoverState] = useState<TPopoverState>({ open: false, event: null });

    const value = store.formatter.currency(store.calculator.remainingBudget);
    const month = store.formatter.monthAndYear(store.view.currentMonth.date);

    const handleChange = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setPopoverState({ open: true, event: e.nativeEvent });
        return false;
    };

    const handleClose = () => {
        setPopoverState({ open: false, event: null });
    }

    return (
        <div className="container ion-text-center ion-margin-top">
            <strong>Budget remaining: {value}</strong>
            <p>{month}, <a href='/change-month' onClick={handleChange}>Change</a></p>


            <MonthPicker state={popoverState} onClose={handleClose} />
        </div>
    )
});

export default RemainingPanel
