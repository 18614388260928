import React, { useRef, useEffect, useState } from 'react'
import { IonPopover, IonList, IonItem, IonButton, IonItemDivider, IonIcon } from '@ionic/react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../Data/Context/StoreContext';
import { Category } from '../../Data/Model/Category';
import { Transaction } from '../../Data/Model/Transaction';
import CurrencyBox from '../../General/Components/CurrencyBox';
import TextBox from '../../General/Components/TextBox';
import { addOutline, removeOutline } from 'ionicons/icons';

interface Props {
    category: Category;
    state: TPopoverState;
    onClose(): void;
}

export type TPopoverState = { open: boolean; event: any };

const TransactionAdder: React.FC<Props> = observer((props) => {

    const store = useStore();
    const [temporaryTransaction, setTemporaryTransaction] = useState(() => new Transaction());
    const [addMode, setAddMode] = useState(false);

    useEffect(() => {
        if (props.state.open) {
            setTemporaryTransaction(new Transaction());
        }
    }, [props.state.open]);


    // Workaround for Autofocus Bug
    // https://github.com/ionic-team/ionic/issues/18132
    const inputRef = useRef<HTMLIonInputElement>(null);
    useEffect(() => {
        if (props.state.open) {
            setTimeout(() => {
                inputRef.current?.setFocus()
            }, 500);
            setTimeout(() => {
                inputRef.current?.setFocus()
            }, 800);
        }
    }, [props.state.open]);

    const toggleAdd = () => {
        setAddMode(x => !x);
        inputRef.current?.setFocus();
    }

    const handleAdd = () => {
        temporaryTransaction.category = props.category;
        temporaryTransaction.amount = Math.abs(temporaryTransaction.amount) * (addMode ? 1 : -1);
        store.actions.addTransaction(temporaryTransaction);
        props.onClose();
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (e.key === 'Enter') {
            handleAdd();
        }
    }

    const tags = props.category.availableTags?.split(' ') ?? [];

    return (
        <IonPopover isOpen={props.state.open} onDidDismiss={props.onClose} cssClass='transaction-adder-popover'>
            <IonList>
                <IonItemDivider>{props.category.text}</IonItemDivider>
                <IonItem>
                    <CurrencyBox inputRef={inputRef} model={temporaryTransaction} property='amount' placeholder='Amount' onKeyUp={handleKeyUp} />
                    <IonButton slot='start' style={{ width: 30 }}
                        color={addMode ? 'positive' : 'negative'}
                        onClick={toggleAdd}>
                        {addMode ? <IonIcon icon={addOutline} /> : <IonIcon icon={removeOutline} />}
                    </IonButton>
                </IonItem>
                <IonItem>
                    <TextBox model={temporaryTransaction} property='text' placeholder='Text (optional)' onKeyUp={handleKeyUp} />
                </IonItem>
                {tags.length > 0 && (
                    <IonItem>
                        {tags.map(tag => (
                            <IonButton key={tag}
                                size="small"
                                color={temporaryTransaction.hasTag(tag) ? 'tertiary' : 'medium'}
                                onClick={() => temporaryTransaction.toggleTag(tag)}
                            >
                                {tag}
                            </IonButton>
                        ))}
                    </IonItem>
                )}
                <IonItem>
                    <IonButton slot="end" color="primary" size="small" onClick={handleAdd}>Add</IonButton>
                </IonItem>
            </IonList>
        </IonPopover>
    )
})

export default TransactionAdder
