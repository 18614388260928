import { observable, IReactionDisposer } from "mobx";
import { serializable, object } from "serializr";

import { generatePassword } from "../../General/Helpers/generatePassword";
import { _LastChange } from "./_LastChange";
import { _Dirty } from "./_Dirty";
import { changeTracker } from "../Helpers/changeTracker";

const GENERATED_PASSWORD_LENGTH = 16;

// bTixbJ0XanTf2iK%

export class SyncConfig {

    @observable
    @serializable
    masterPassword: string = generatePassword(GENERATED_PASSWORD_LENGTH);

    @observable
    @serializable
    enabled: boolean = false;



    @serializable(object(_LastChange))
    lastChanged = new _LastChange(this);

    dirty = new _Dirty(this);


    constructor() {
        changeTracker(this);
    }

    unsubscriber?: IReactionDisposer;

    public dispose() {
        this.unsubscriber && this.unsubscriber();
    }

    public changeDetection() {
        return [this.masterPassword, this.enabled];
    }

}
