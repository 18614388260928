import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonContent
} from '@ionic/react';
import { cashOutline, pieChartOutline, receiptOutline, settingsOutline } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import BudgetPage from './Features/BudgetPage/Pages/BudgetPage';
import BillingPage from './Features/BillingPage/Pages/BillingPage';
//import SettingsPage from './Features/SettingsPage/Pages/SettingsPage';
//import StatsPage from './Features/StatsPage/Pages/StatsPage';
import StatsOverviewPage from './Features/StatsPage/Pages/OverviewPage';
import TopCategoriesPage from './Features/StatsPage/Pages/TopCategoriesPage';
import SettingsOverviewPage from './Features/SettingsPage/Pages/OverviewPage';
import CategoryFormPage from './Features/SettingsPage/Pages/CategoryFormPage';
import CategoriesPage from './Features/SettingsPage/Pages/CategoriesPage';
import I18nPage from './Features/SettingsPage/Pages/I18nPage';
import SyncSetupPage from './Features/SettingsPage/Pages/SyncSetupPage';
import DropboxAuthRedirectPage from './Features/Synchronization/Pages/DropboxAuthRedirectPage';
import DropboxAuthStartPage from './Features/Synchronization/Pages/DropboxAuthStartPage';

const App: React.FC = () => {

    return (
        <IonApp>
            <IonContent>
                <IonTabs>
                    <IonRouterOutlet>

                        {/* Currently directly define all routes including nesting in ROOT,
                            nested routes currently having issues with
                             - not allowing to un-render component
                             - overlapping pages at the same time
                            https://github.com/ionic-team/ionic/issues/20597
                            https://github.com/ionic-team/ionic/issues/20219

                            SHOULD BE:

                            <Route path="/budget" component={BudgetPage} />
                            <Route path="/billing" component={BillingPage} />
                            <Route path="/settings" component={SettingsPage} />
                            <Route path="/stats" component={StatsPage} />

                            TODO: Revert to nested routing

                         */}

                        <Route path="/budget" component={BudgetPage} />
                        <Route path="/billing" component={BillingPage} />

                        <Route path="/stats/top-categories" component={TopCategoriesPage} />
                        <Route path="/stats" component={StatsOverviewPage} />

                        <Route path="/settings/categories/:id" component={CategoryFormPage} />
                        <Route path="/settings/categories" component={CategoriesPage} />
                        <Route path="/settings/i18n" component={I18nPage} />
                        <Route path="/settings/sync" component={SyncSetupPage} />
                        <Route path="/settings" component={SettingsOverviewPage} />

                        <Route path="/sync/auth/dropbox/start" component={DropboxAuthStartPage} />
                        <Route path="/sync/auth/dropbox/redirect" component={DropboxAuthRedirectPage} />

                        <Route exact path="/" render={() => <Redirect to="/budget" />} />
                    </IonRouterOutlet>

                    <IonTabBar slot="bottom">
                        <IonTabButton tab="budget" href="/budget">
                            <IonIcon icon={cashOutline} />
                            <IonLabel>Budget</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="stats" href="/stats">
                            <IonIcon icon={pieChartOutline} />
                            <IonLabel>Stats</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="bill" href="/billing">
                            <IonIcon icon={receiptOutline} />
                            <IonLabel>Billing</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="settings" href="/settings">
                            <IonIcon icon={settingsOutline} />
                            <IonLabel>Settings</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonContent>
        </IonApp>
    );
}

export default App;
