import { observable, IReactionDisposer } from "mobx";
import { serializable, identifier, date, reference, list, primitive, object } from "serializr";
import shortid from "shortid";

import { Category } from "./Category";
import { _Dirty } from "./_Dirty";
import { _LastChange } from "./_LastChange";
import { changeTracker } from "../Helpers/changeTracker";
import { localStore } from "../Store/Store";


function lookupCategory(id: string, callback: (err: any, result: any) => void) {
    const category = localStore.data.categories.get(id);
    if (category)
        callback(null, category);
    else
        callback(new Error(`Category ${id} not found`), null);
}

export class Transaction {


    @serializable(identifier())
    uid = shortid.generate();

    @observable
    @serializable
    amount: number = 0;

    @observable
    @serializable
    text?: string;

    @observable
    @serializable(date())
    date?: Date;

    @observable
    @serializable(reference(Category, lookupCategory))
    category?: Category;

    @observable
    @serializable(list(primitive()))
    private _tags?: string[];

    public get tags() {
        return this._tags as ReadonlyArray<string>;
    }

    @serializable(object(_LastChange))
    lastChanged = new _LastChange(this);

    dirty = new _Dirty(this);



    toggleTag(tag: string) {
        if (!this._tags)
            this._tags = [];

        const idx = this._tags.indexOf(tag);
        if (idx >= 0)
            this._tags.splice(idx, 1);
        else
            this._tags.push(tag);
    }

    hasTag(tag: string) {
        if (!this._tags) return false;
        return this._tags.indexOf(tag) >= 0;
    }

    applyFrom(otherTransaction: Transaction) {
        this.amount = otherTransaction.amount;
        this.text = otherTransaction.text;
        this.date = otherTransaction.date;
        this.category = otherTransaction.category;
        this._tags = otherTransaction._tags;
    }



    constructor() {
        changeTracker(this);
    }

    unsubscriber?: IReactionDisposer;

    public dispose() {
        this.unsubscriber && this.unsubscriber();
    }

    public changeDetection() {
        return [this.amount, this.text, this.category, this._tags?.length];
    }

}