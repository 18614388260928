import React, { useEffect } from 'react'

import { useStore } from '../../Data/Context/StoreContext';
import GenericNavigationPage from '../../General/Pages/GenericNavigationPage'
import Center from '../../General/Components/Center';

interface Props {

}

const DropboxAuthStartPage = (props: Props) => {
    const store = useStore();
    useEffect(() => {
        store.sync.beginSignIn();
    }, [store]);

    return (
        <GenericNavigationPage title='Authentication'>
            <Center>
                <div className="ion-text-center">
                    <div className="sk-folding-cube">
                        <div className="sk-cube1 sk-cube"></div>
                        <div className="sk-cube2 sk-cube"></div>
                        <div className="sk-cube4 sk-cube"></div>
                        <div className="sk-cube3 sk-cube"></div>
                    </div>

                    <p>Connecting with Dropbox</p>
                    <p>Please wait...</p>
                </div>
            </Center>
        </GenericNavigationPage>
    )
}

export default DropboxAuthStartPage
