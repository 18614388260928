import { observable } from "mobx";
import { serializable, object, reference, serialize, deserialize } from "serializr";

import { Month } from "./Month";
import { Categories } from "./Categories";
import { Months } from "./Months";
import { Transactions } from "./Transactions";

export class Data {

    @serializable(object(Months))
    months = new Months();

    @serializable(object(Categories))
    categories = new Categories();

    @observable
    @serializable(reference(Month))
    currentMonth?: Month;

    /** Contains transactions currently loaded */
    loadedTransactions: IIntHashtable<Transactions> = {};

    
  

}

interface IIntHashtable<T> {
    [key: number]: T;
}