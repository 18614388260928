import React, { useState, useEffect } from 'react'
import { IonButton, IonIcon, IonToast, IonSpinner } from '@ionic/react'
import { syncOutline } from 'ionicons/icons'
import { useStore, useStoreManager } from '../../Data/Context/StoreContext'
import { observer } from 'mobx-react-lite'

interface Props {

}

const SyncTrigger = observer(() => {
    const store = useStore();
    const storemanager = useStoreManager();

    const [isSyncing, setIsSyncing] = useState(false);

    const handleClick = () => {
        (async () => {
            setIsSyncing(true);
            await storemanager.saveStore(store);
            await store.sync.doSync();
            setIsSyncing(false);
        })();
    }

    if (!store.syncConfig.enabled)
        return null;

    return (
        <>
            {isSyncing && (
                <IonSpinner name="dots" />
            )}
            <IonButton onClick={handleClick} disabled={isSyncing}>
                <IonIcon icon={syncOutline}></IonIcon>
            </IonButton>

            <IonToast isOpen={isSyncing} message={store.sync.syncStatusMessage} duration={2000} />
        </>
    )
})

export default SyncTrigger
