import { observable } from 'mobx';
import { serializable, object, deserialize } from 'serializr';

import { Configuration } from "../Model/Configuration";
import { View } from "../BusinessLogic/View";
import { Data } from '../Model/Data';
import { Calculator } from '../BusinessLogic/Calculator';
import { Actions } from '../BusinessLogic/Actions';
import { Formatter } from '../BusinessLogic/Formatter';
import { Sync } from '../BusinessLogic/Sync';
import { Loader } from '../BusinessLogic/Loader';
import { SyncConfig } from '../Model/SyncConfig';
import { Serializer } from '../BusinessLogic/Serializer';

export class Store {

    @serializable(object(Configuration))
    config = new Configuration();

    @serializable(object(Data))
    data = new Data();

    @serializable(object(SyncConfig))
    syncConfig = new SyncConfig();

    view = new View(this);
    calculator = new Calculator(this);
    actions = new Actions(this);
    formatter = new Formatter(this);
    sync = new Sync(this);
    loader = new Loader(this);
    serializer = new Serializer();

    @observable
    dataLoaded = false;


    

}

export const localStore = new Store();
console.log('STORE', localStore);