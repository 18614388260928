import { observable, IReactionDisposer } from 'mobx';
import { serializable, object } from 'serializr';

import { _LastChange } from './_LastChange';
import { _Dirty } from './_Dirty';
import { changeTracker } from '../Helpers/changeTracker';
import { Config } from '@testing-library/react';

export class Configuration {
    @observable
    @serializable
    locale: string = 'de-AT';

    @observable
    @serializable
    currency: string = 'EUR';

    @observable
    @serializable
    availableBudget: number = 1000;

    @serializable(object(_LastChange))
    lastChanged = new _LastChange(this);

    dirty = new _Dirty(this);


    constructor() {
        changeTracker(this);
    }

    unsubscriber?: IReactionDisposer;

    public dispose() {
        this.unsubscriber && this.unsubscriber();
    }

    public changeDetection() {
        return [this.locale, this.currency, this.availableBudget];
    }


    syncFromRemote(remoteConfig: Configuration) {
        if (this.lastChanged.get() > remoteConfig.lastChanged.get()) {
            return true;
        } else if (this.lastChanged.get() < remoteConfig.lastChanged.get()) {
            this.applyFrom(remoteConfig);
        }
        return false;
    }

    applyFrom(other: Configuration) {
        this.currency = other.currency;
        this.locale = other.locale;
        this.availableBudget = other.availableBudget;
    }

}
