import React, { useState } from 'react'
import { IonCol, IonIcon, IonCardContent, IonCard, IonCardHeader, IonCardSubtitle } from '@ionic/react'
import { AllIcons } from '../../Data/Constants/Icons'
import { useStore } from '../../Data/Context/StoreContext'
import TransactionAdder, { TPopoverState } from './TransactionAdder'
import { Category } from '../../Data/Model/Category'
import { observer } from 'mobx-react-lite'

interface Props {
    category: Category;
}

const CategoryGridItem = observer((props: Props) => {
    const store = useStore();
    const icon = props.category.icon ? AllIcons[props.category.icon]?.data ?? undefined : undefined;
    const amount = store.formatter.currency(store.calculator.getAmountForCategory(props.category));
    
    const [adderState, setAdderState] = useState<TPopoverState>({ open: false, event: null })

    const handleClick = (e: React.MouseEvent<HTMLIonCardElement>) => {
        setAdderState({ open: true, event: e.nativeEvent });
    };
    const handleClose = () => {
        setAdderState({ open: false, event: null });
    }

    return (
        <IonCol sizeXs='3' sizeSm='2' sizeMd='2' style={{ minWidth: 110 }}>
            <IonCard className='ion-no-margin' button onClick={handleClick}>
                {icon && <IonIcon icon={icon} size="large" className='ion-margin-top' />}
                <IonCardHeader className='ion-no-padding'>
                    <IonCardSubtitle>
                        <small>{props.category.text}</small>
                    </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent className='ion-text-nowrap'>
                    {amount}
                </IonCardContent>
            </IonCard>

            <TransactionAdder state={adderState} onClose={handleClose} category={props.category} />
        </IonCol>
    )
})

export default CategoryGridItem
