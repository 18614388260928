import { Dropbox } from 'dropbox';

import { Store } from "../../Data/Store/Store";

// TODO: Constants file...
const APP_KEY = 'fm1k3fvt5c1qkfj';
//const HOST = 'http://localhost:8100';
const HOST = 'https://budget-dev-x38.app.edelms.at';

const AUTH_REDIRECT_PATH = '/sync/auth/dropbox/redirect';

export class DropboxSyncClient {

    private dbx: Dropbox;
    private currentTimestamp: number = 0;

    constructor(private store: Store) {

        const accessToken = window.localStorage.getItem('dbx_accessToken');
        const refreshToken = window.localStorage.getItem('dbx_refreshToken');
        const accessTokenExpiresAt = window.localStorage.getItem('dbx_accessTokenExpiresAt');

        this.currentTimestamp = accessTokenExpiresAt ? +accessTokenExpiresAt : 0;



        this.dbx = new Dropbox({
            clientId: APP_KEY,
            fetch: fetch.bind(window), // Fix for getting error after receiving authentication response code
            accessToken:
                accessToken ?? undefined,
            refreshToken:
                refreshToken ?? undefined,
            accessTokenExpiresAt:
                accessTokenExpiresAt ? new Date(this.currentTimestamp) : undefined
        });
    }

    public get isAuthenticated() {
        return !!this.dbx.getRefreshToken();
    }

    public async checkAndRefreshToken() {
        // Missing typings...
        const untypedDbx: any = this.dbx;
        await untypedDbx.checkAndRefreshAccessToken();

        // Update in local storage
        if (this.dbx.getAccessTokenExpiresAt().getTime() !== this.currentTimestamp) {
            window.localStorage.setItem('dbx_accessToken', this.dbx.getAccessToken());
            window.localStorage.setItem('dbx_accessTokenExpiresAt', this.dbx.getAccessTokenExpiresAt().getTime() + '');
            this.currentTimestamp = this.dbx.getAccessTokenExpiresAt().getTime();
            console.log('Access token updated');
        }
    }

    public async testToken() {
        await this.checkAndRefreshToken();
        try {
            const account = await this.dbx.usersGetCurrentAccount();
            console.log('Testing API', 'user account = ', account);
            return true;
        } catch (err) {
            console.error('Token test failed', err);
            return false;
        }
    }

    /** Starts the sign in process, this will redirect the user to the oauth login */
    startSignIn() {

        const authUrl = this.dbx.getAuthenticationUrl(HOST + AUTH_REDIRECT_PATH, undefined, 'code', 'offline', undefined, 'none', true);
        console.log(authUrl);

        // Save generated PKCE code verifier + code challenge
        // after redirect the Dropbox api instance is lost!
        const pkceChallenge: string = (this.dbx as any).codeChallenge;
        const pkceVerifier: string = (this.dbx as any).codeVerifier;
        window.localStorage.setItem('dbx_codeChallenge', pkceChallenge);
        window.localStorage.setItem('dbx_codeVerifier', pkceVerifier);

        // Open authentication page
        window.location.href = authUrl;

        // TODO: test if works in PWA


    }

    /** Completes the sign in process, this will receive the token after the oauth login has completed */
    async completeSignIn(code: string) {

        console.log('Completing sign in with the code ...');
        console.log(code);

        // Restore generated PKCE code verifier + code challenge
        // after redirect the Dropbox api instance is lost!
        const pkceChallenge = window.localStorage.getItem('dbx_codeChallenge');
        const pkceVerifier = window.localStorage.getItem('dbx_codeVerifier');
        if (!pkceChallenge || !pkceVerifier)
            throw new Error('PKCE challenge failed');

        (this.dbx as any).codeChallenge = pkceChallenge;
        (this.dbx as any).codeVerifier = pkceVerifier;

        const tokenStr = await this.dbx.getAccessTokenFromCode(HOST + AUTH_REDIRECT_PATH, code);
        // Typing issue, receives not as string!
        const token = tokenStr as any as { accessToken: string, accessTokenExpiresAt: Date, refreshToken: string };

        console.log('Received token', token);

        window.localStorage.removeItem('dbx_codeChallenge');
        window.localStorage.removeItem('dbx_codeVerifier');
        window.localStorage.setItem('dbx_accessToken', token.accessToken);
        window.localStorage.setItem('dbx_accessTokenExpiresAt', token.accessTokenExpiresAt.getTime() + '');
        window.localStorage.setItem('dbx_refreshToken', token.refreshToken);
        return true;
    }

    async listFolder(path: string) {
        await this.checkAndRefreshToken();
        console.log('Listing', path);
        const meta = await this.dbx.filesListFolder({ path: path });
        console.log(meta);
    }

    async getFile(filename: string) {
        return new Promise<string>(async (resolve, reject) => {

            await this.checkAndRefreshToken();

            let file: any; // Typing issues...
            try {
                // cats.bdf
                console.log('Getting', filename);
                file = await this.dbx.filesDownload({ path: `${filename}` });
            } catch (err) {
                reject(new Error(err.status === 409 ? 'not-found' : 'unknown'));
                console.error(err);
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(reader.result as string);
            };
            reader.onerror = (e) => {
                console.error('READER ERROR', e, reader);
                reject(e);
            }

            reader.readAsText(file.fileBlob);
        });
    }

    async setFile(filename: string, content: string) {
        await this.checkAndRefreshToken();

        const result = await this.dbx.filesUpload({
            path: filename,
            contents: content,
            mode: { '.tag': 'overwrite' },
            mute: true
        });

        console.log('UPLOAD RESULT', result);
        

    }


}
