import React from 'react'
import { AllIcons, TIcon } from '../../Data/Constants/Icons'
import { IonIcon } from '@ionic/react';

interface Props {
    selectedIcon?: TIcon;
    onChange?(icon: TIcon): void;
}

const IconPicker = (props: Props) => {

    const keys = Object.keys(AllIcons) as TIcon[];

    const handleClick = (key: TIcon) => {
        if (props.onChange)
            props.onChange(key);
    }

    return (
        <div className='icon-picker'>
            {keys.map(key => {
                return (
                    <button type='button' key={key}
                        className={props.selectedIcon === key ? 'selected' : ''}
                        onClick={() => handleClick(key)}
                    >
                        <IonIcon icon={AllIcons[key].data} size='large' />
                    </button>
                )
            })}
        </div>
    )
}

export default IconPicker
