import { observable, IReactionDisposer } from "mobx";
import { serializable, object, list } from "serializr";

import { Transaction } from "./Transaction";
import { _LastChange } from "./_LastChange";
import { _Dirty } from "./_Dirty";
import { changeTracker } from "../Helpers/changeTracker";

export class Transactions {

    @observable
    @serializable(list(object(Transaction)))
    private _items: Transaction[] = [];

    @serializable(object(_LastChange))
    lastChanged = new _LastChange(this);

    dirty = new _Dirty(this);


    public get all() {
        return this._items as ReadonlyArray<Transaction>;
    }

    public insert(transaction: Transaction) {
        this._items.push(transaction);
    }

    public remove(transaction: Transaction) {
        const idx = this._items.indexOf(transaction);
        if (idx >= 0) {
            this._items[idx].dispose();
            this._items.splice(idx, 1);
        }
    }

    syncFromRemote(remote: Transactions) {
        const own = this.all;
        const other = remote.all;
        let hasChangedLocally = false;

        // TODO: check removed months on both ends...

        // Check months existing on both sides => sync transactions
        own.forEach(ownTransaction => {
            const otherTransaction = other.find(x => x.uid === ownTransaction.uid);
            if (!otherTransaction) return;

            // Other is newer -> sync to own
            if (ownTransaction.lastChanged < otherTransaction.lastChanged) {
                ownTransaction.applyFrom(otherTransaction);
            }

            // Local is newer => note change
            if (ownTransaction.lastChanged > otherTransaction.lastChanged) {
                hasChangedLocally = true;
            }
        });

        // Check where other has new items
        other.forEach(transaction => {
            if (!own.some(x => x.uid === transaction.uid)) {
                // Is new month
                this.insert(transaction);
            }
        });

        // Check where own has new items
        own.forEach(transaction => {
            if (!other.some(x => x.uid === transaction.uid)) {
                // Already in own -> nothing to do but detect change
                hasChangedLocally = true;
            }
        })

        return hasChangedLocally;
    }



    constructor() {
        changeTracker(this);
    }
    
    unsubscriber?: IReactionDisposer;

    public dispose() {
        this.unsubscriber && this.unsubscriber();
    }

    public changeDetection() {
        return [this._items?.length];
    }

}