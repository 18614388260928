import { observable, IReactionDisposer, reaction } from "mobx";
import { serializable, date, object, identifier } from "serializr";
import dayjs from "dayjs";
import shortid from "shortid";

import { Transactions } from "./Transactions";
import { _Dirty } from "./_Dirty";
import { localStore } from "../Store/Store";
import { _LastChange } from "./_LastChange";
import { changeTracker } from "../Helpers/changeTracker";

export class Month {
    @serializable(identifier())
    uid = shortid.generate();

    @serializable(date())
    date = dayjs().startOf('month').toDate();

    @observable
    @serializable
    availableBudget?: number;

    /** Timestamp when the transactions has been changed (updated on save!) */
    @observable
    @serializable
    transactionsChanged: number = 0;

    
    public get transactions(): Transactions {
        const timestamp = this.date.getTime();
        if (!localStore.data.loadedTransactions[timestamp]) {
            localStore.data.loadedTransactions[timestamp] = localStore.loader.transactions(this.date);
        }
        return localStore.data.loadedTransactions[timestamp];
    }

    

    @serializable(object(_LastChange))
    lastChanged = new _LastChange(this);

    dirty = new _Dirty(this);

    constructor() {
        changeTracker(this);
    }

    unsubscriber?: IReactionDisposer;

    public dispose() {
        this.unsubscriber && this.unsubscriber();
    }


    public changeDetection() {
        return [this.availableBudget, this.transactionsChanged];
    }



    syncFromRemote(remote: Month) {

        let hasChangedLocally = false;

        if (this.lastChanged.get() > remote.lastChanged.get()) {
            // Local newer
            hasChangedLocally = true;

        } else if (remote.lastChanged.get() > this.lastChanged.get()) {
            // Remote newer
            this.availableBudget = remote.availableBudget;
            this.transactionsChanged = remote.transactionsChanged;
        }

        hasChangedLocally = this.transactions.syncFromRemote(remote.transactions) || hasChangedLocally;

        return hasChangedLocally;
    }


}