import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSegmentButton, IonSegment } from '@ionic/react';
import Bill from '../Components/Bill';


const BillingPage: React.FC = () => {
    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Billing Mar 2020</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Billing Mar 2020</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonSegment value='individual'>
                    <IonSegmentButton value="individual">
                        per Transaction
                    </IonSegmentButton>
                    <IonSegmentButton value="per-category">
                        per Category
                    </IonSegmentButton>
                </IonSegment>

                <Bill />

            </IonContent>
        </IonPage>
    );
};

export default BillingPage;
