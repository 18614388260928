import React, { createContext, useState, useEffect, useContext } from "react";
import LoadingPage from "../Components/LoadingPage";
import { Store } from "../Store/Store";
import { StoreManager } from "../Store/StoreManager";

interface IStoreContext {
    storeManager: StoreManager | null;
    store: Store | null;
}

const storeContext = createContext<IStoreContext | null>(null);

export const StoreProvider: React.FC = ({ children }) => {
    const [storeManager] = useState(() => new StoreManager());
    const [store, setStore] = useState<Store | null>(null);

    // Initial load
    useEffect(() => {
        (async () => {
            const storeObj = await storeManager.initStore();
            setStore(storeObj);
        })();
    }, [storeManager]);

    return (
        <storeContext.Provider value={{ store, storeManager }}>
            {store ? children : <LoadingPage />}
        </storeContext.Provider>
    );
};

export const useStore = () => {
    const store = useContext(storeContext);
    if (!store || !store.store)
        throw new Error('useStore must be used within a StoreProvider!');
    return store.store;
};

export const useStoreManager = () => {
    const store = useContext(storeContext);
    if (!store || !store.storeManager)
        throw new Error('useStoreManager must be used within a StoreProvider!');
    return store.storeManager;
};