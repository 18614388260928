import { Store } from "../Store/Store";

export class Formatter {

    constructor(private store: Store) { }

    currency(value: number | undefined) {
        if (typeof value === 'undefined') return '';

        return value.toLocaleString(
            this.store.config.locale,
            { style: 'currency', currency: this.store.config.currency }
        );
    }

    currencySimple(value: number | undefined) {
        if (typeof value === 'undefined') return '';

        // TODO: cache these ???
        const numberOptions =
            new Intl.NumberFormat(this.store.config.locale, { style: 'currency', currency: this.store.config.currency })
                .resolvedOptions();

        return value.toLocaleString(
            this.store.config.locale,
            { ...numberOptions, style: 'decimal', useGrouping: false }
        );
    }

    monthAndYear(value: Date) {
        return value.toLocaleString(
            this.store.config.locale,
            { month: 'long', year: 'numeric' }
        )
    }
    dayMonthAndTime(value: Date) {
        return value.toLocaleString(
            this.store.config.locale,
            { day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric' }
        )
    }


    monthNames() {
        const format = new Intl.DateTimeFormat(this.store.config.locale, { month: 'long' })
        const months: string[] = []
        for (var month = 0; month < 12; month++) {
            const testDate = new Date(Date.UTC(2000, month, 1, 0, 0, 0));
            months.push(format.format(testDate))
        }
        return months;
    }

    years() {
        let minYear = 10000;
        let maxYear = -10000;
        this.store.data.months.all.forEach(month => {
            if (month.date.getFullYear() < minYear)
                minYear = month.date.getFullYear();
            if (month.date.getFullYear() > maxYear)
                maxYear = month.date.getFullYear();
        });

        minYear -= 1; // Allow 1 year below min
        maxYear += 2; // Allow 2 years above max

        const years: number[] = [];
        for (let i = minYear; i <= maxYear; i++) {
            years.push(i);
        }
        return years;

    }

}
