import { search } from "ionicons/icons";


let searchedObjects: any[] = [];

/** Find all instances of class 'type' in the 'obj' including child objects and arrays */
export function findInstancesInObjectTree<T extends any>(obj: any, type: Function): T[] {
    searchedObjects = [];
    return internalFindInstancesInObjectTree(obj, type);
}

function internalFindInstancesInObjectTree<T extends any>(obj: any, type: Function): T[] {

    // Avoid circular references
    if (searchedObjects.indexOf(obj) >= 0)
        return [];
    searchedObjects.push(obj);

    if (obj instanceof type) {
        return [obj] as T[];
    }

    if (obj && Array.isArray(obj)) {
        const results: T[] = [];
        obj.forEach(item => {
            if (item === obj) return;
            const res = internalFindInstancesInObjectTree(item, type);
            results.push(...res);
        });
        return results;
    }

    if (obj && typeof obj === 'object') {
        const results: T[] = [];
        Object
            .keys(obj)
            .forEach(key => {
                const prop = obj[key];
                if (prop === obj) return;

                const res = internalFindInstancesInObjectTree(prop, type);
                results.push(...res);
            });
        return results;
    }

    return [];
}

