import { observable, IReactionDisposer } from "mobx";
import { serializable, identifier, object } from "serializr";
import shortid from "shortid";

import { TIcon } from "../Constants/Icons";
import { _Dirty } from "./_Dirty";
import { _LastChange } from "./_LastChange";
import { changeTracker } from "../Helpers/changeTracker";

export class Category {

    @serializable(identifier())
    uid = shortid.generate();

    @observable
    @serializable
    icon?: TIcon;

    @observable
    @serializable
    text: string;

    @observable
    @serializable
    availableTags?: string;

    @serializable(object(_LastChange))
    lastChanged = new _LastChange(this);

    dirty = new _Dirty(this);


    constructor(text: string = '', icon?: TIcon) {
        this.text = text;
        this.icon = icon;
        changeTracker(this);
    }
    
    unsubscriber?: IReactionDisposer;

    public dispose() {
        this.unsubscriber && this.unsubscriber();
    }

    public changeDetection() {
        return [this.icon, this.text, this.availableTags?.length];
    }


    public applyFrom(otherCategory: Category) {
        this.text = otherCategory.text;
        this.icon = otherCategory.icon;
        this.availableTags = otherCategory.availableTags;
    }

}