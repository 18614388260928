import React from 'react'
import { IonItem, IonLabel, IonReorderGroup, IonReorder, IonIcon, IonButton } from '@ionic/react'
import { ItemReorderEventDetail } from '@ionic/core';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../Data/Context/StoreContext';
import { AllIcons } from '../../Data/Constants/Icons';
import { addOutline } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router';
import GenericNavigationPage from '../../General/Pages/GenericNavigationPage';

interface Props extends RouteComponentProps {

}

const CategoriesPage = observer((props: Props) => {
    const store = useStore();

    const handleReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
        // Move category in model
        store.data.categories.move(event.detail.from, event.detail.to);
        // false: do not reorder by ionic, model is already reordered
        event.detail.complete(false);
    }

    const handleAdd = () => {
        const newCategory = store.data.categories.create();
        props.history.push(`/settings/categories/${newCategory.uid}`);
    }

    return (

        <GenericNavigationPage
            title='Categories'
            titleEndButton={
                <IonButton onClick={handleAdd}>
                    <IonIcon icon={addOutline} size='large' />
                </IonButton>
            }
        >

            <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>

                {store.data.categories.all.map(category => {
                    const icon = category.icon ? AllIcons[category.icon]?.data ?? undefined : undefined;
                    return (
                        <IonItem key={category.uid} routerLink={`/settings/categories/${category.uid}`}>


                            {icon && <IonIcon slot='start' icon={icon} className='ion-margin-top' />}

                            <IonLabel>{category.text}</IonLabel>
                            <IonReorder slot="end" />
                        </IonItem>
                    )
                })}

            </IonReorderGroup>

        </GenericNavigationPage>
    )
})

export default CategoriesPage
