import { carOutline, basketOutline, cartOutline, americanFootballOutline, bandageOutline, fastFoodOutline, pintOutline, shirtOutline, airplaneOutline, colorPaletteOutline, homeOutline, shieldCheckmarkOutline, walletOutline, filmOutline, helpOutline, trendingDownOutline, schoolOutline, addOutline, alarmOutline, alertOutline, analyticsOutline, apertureOutline, appsOutline, atOutline, attachOutline, barbellOutline, baseballOutline, basketballOutline, batteryHalfOutline, removeOutline, reorderTwoOutline } from 'ionicons/icons'

export type TIcon = keyof typeof AllIcons;

export const AllIcons = {

    add: {
        name: 'Add',
        data: addOutline
    },
    airplane: {
        name: 'Plane',
        data: airplaneOutline
    },
    alarm: {
        name: 'Alarm',
        data: alarmOutline
    },
    alert: {
        name: 'Alert',
        data: alertOutline
    },
    americanFootball: {
        name: 'American Football',
        data: americanFootballOutline
    },
    analytics: {
        name: 'Analytics',
        data: analyticsOutline
    },
    aperture: {
        name: 'Aperture',
        data: apertureOutline
    },
    apps: {
        name: 'Apps',
        data: appsOutline
    },
    at: {
        name: 'At',
        data: atOutline
    },
    attach: {
        name: 'Attach',
        data: attachOutline
    },
    bandage: {
        name: 'Bandage',
        data: bandageOutline
    },
    barbell: {
        name: 'Barbell',
        data: barbellOutline
    },
    baseball: {
        name: 'Baseball',
        data: baseballOutline
    },
    basket: {
        name: 'Basket',
        data: basketOutline
    },
    basketball: {
        name: 'Basketball',
        data: basketballOutline
    },
    battery: {
        name: 'Battery',
        data: batteryHalfOutline
    },

    // TODO: weiter ab Bed

    car: {
        name: 'Car',
        data: carOutline
    },

    cart: {
        name: 'Cart',
        data: cartOutline
    },
    
    fastfood: {
        name: 'Fast Food',
        data: fastFoodOutline
    },

    film: {
        name: 'Film',
        data: filmOutline
    },

    help: {
        name: 'Help',
        data: helpOutline
    },

    home: {
        name: 'Home',
        data: homeOutline
    },

    palette: {
        name: 'Color Palette',
        data: colorPaletteOutline
    },

    pint: {
        name: 'Pint',
        data: pintOutline
    },

    remove: {
        name: 'Remove',
        data: removeOutline
    },

    reorderTwo: {
        name: 'Reorder Two',
        data: reorderTwoOutline
    },

    school: {
        name: 'School',
        data: schoolOutline
    },

    shieldCheckmark: {
        name: 'Shield Checkmark',
        data: shieldCheckmarkOutline
    },

    shirt: {
        name: 'Shirt',
        data: shirtOutline
    },

    trendingDown: {
        name: 'Trending Down',
        data: trendingDownOutline
    },

    wallet: {
        name: 'Wallet',
        data: walletOutline
    },


}