import { Store } from "../../Data/Store/Store";
import CryptoJS from "crypto-js";

export class EncryptionService {

    constructor(private store: Store) { }

    public encryptObject(data: any) {
        const dataJson = JSON.stringify(data);
        const encrypted = CryptoJS.AES.encrypt(dataJson, this.store.syncConfig.masterPassword);
        return encrypted.toString();//CryptoJS.enc.Utf8);
    }

    public decryptObject(data: string) {
        const bytes = CryptoJS.AES.decrypt(data, this.store.syncConfig.masterPassword);
        const origText = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(origText);
    }
}
