import { Store } from "../Store/Store";
import { Category } from "../Model/Category";

export class Calculator {

    constructor(private store: Store) { }

    getAmountForCategory(category: Category) {
        const currentMonth = this.store.view.currentMonth;
        if (!currentMonth) return 0;

        return currentMonth.transactions.all
            .filter(x => x.category === category)
            .reduce((sum, x) => sum += x.amount, 0);
    }


    get total() {
        return this.store.view.currentMonth.transactions.all
            .reduce((sum, x) => sum += x.amount, 0);
    }

    get totalExpenses() {
        return this.store.view.currentMonth.transactions.all
            .filter(x => x.amount < 0)
            .reduce((sum, x) => sum += x.amount, 0);
    }

    get totalIncome() {
        return this.store.view.currentMonth.transactions.all
            .filter(x => x.amount > 0)
            .reduce((sum, x) => sum += x.amount, 0);
    }

    get availableBudget() {
        const currentMonth = this.store.view.currentMonth;
        if (currentMonth && currentMonth.availableBudget)
            return currentMonth.availableBudget;
        return this.store.config.availableBudget;
    }

    get remainingBudget() {
        const currentMonth = this.store.view.currentMonth;
        if (!currentMonth) return this.store.config.availableBudget;

        var availableBudget = currentMonth.availableBudget ?? this.store.config.availableBudget;
        return availableBudget + this.total;
    }

}
