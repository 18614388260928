import dayjs from 'dayjs';
import { Month } from '../Model/Month';
import { Store } from '../Store/Store';

export class View {
    constructor(private store: Store) { }


    get currentMonth(): Month {
        if (!this.store.data.currentMonth) {
            this.store.data.currentMonth = this.store.data.months.ensure();
        }
        return this.store.data.currentMonth;
    }

    set currentMonth(month: Month) {
        this.store.data.currentMonth = month;
    }

    ensureAndSelectMonth(month: number, year: number) {
        var date = dayjs().year(year).month(month).startOf('month').toDate();

        const m = this.store.data.months.ensure(date);
        this.currentMonth = m;
        return m;
    }

}
