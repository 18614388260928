import React from 'react'
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonIcon } from '@ionic/react'
import { settingsOutline, syncOutline, checkmarkOutline } from 'ionicons/icons'
import { useHistory } from 'react-router'
import { useStore } from '../../Data/Context/StoreContext'
import { Category } from '../../Data/Model/Category'

import empty_header from '../../../assets/empty_categories_header.png';

interface Props {

}

const CategoryHelper = () => {
    const store = useStore();
    const history = useHistory();

    const handleCategorySettingsClick = () => {
        history.push('/settings/categories');
    }

    const handleSyncSettingsClick = () => {
        history.push('/settings/sync');
    }

    const handleCreateSampleCategoriesClick = () => {
        store.data.categories.insert(new Category('Groceries', 'basket'));
        store.data.categories.insert(new Category('Food', 'fastfood'));
        store.data.categories.insert(new Category('Drinks', 'pint'));
        store.data.categories.insert(new Category('Entertainment', 'film'));
        store.data.categories.insert(new Category('Hobby', 'palette'));
        store.data.categories.insert(new Category('Clothing', 'shirt'));
        store.data.categories.insert(new Category('Home', 'home'));
        store.data.categories.insert(new Category('Car', 'car'));
        store.data.categories.insert(new Category('Travelling', 'airplane'));
        store.data.categories.insert(new Category('Sports', 'americanFootball'));
        store.data.categories.insert(new Category('Health', 'bandage'));
        store.data.categories.insert(new Category('Learning', 'school'));
        store.data.categories.insert(new Category('Insurance', 'shieldCheckmark'));
        store.data.categories.insert(new Category('Saving', 'wallet'));
        store.data.categories.insert(new Category('Taxes & Fees', 'trendingDown'));
        store.data.categories.insert(new Category('Other', 'help'));
    }

    return (
        <div style={{ maxWidth: 700 }}>
            <IonCard className='ion-text-left'>
                <img src={empty_header} />
                <IonCardContent>
                    <p>
                        It seems you have no categories.
                        If you are new to this app, we can create some pre-made categories for you.
                        Or go directly to the settings page and create your own.
                    </p>
                </IonCardContent>
            </IonCard>


            <IonButton onClick={handleCreateSampleCategoriesClick}>
                <IonIcon slot='start' icon={checkmarkOutline} />
                Create some for me
            </IonButton>
            <IonButton onClick={handleCategorySettingsClick}>
                <IonIcon slot='start' icon={settingsOutline} />
                Create your own
            </IonButton>

            <br /><br />

            <IonCard className='ion-text-left'>
                <IonCardContent>
                    <p>
                        If you are already using the app on a different device, go to the sync settings,<br />
                        and enter your dropbox user and your master password to download the data.
                    </p>
                </IonCardContent>
            </IonCard>

            <IonButton color='secondary' onClick={handleSyncSettingsClick}>
                <IonIcon slot='start' icon={syncOutline} />
                Synchronization
            </IonButton>
        </div>
    )
}

export default CategoryHelper
