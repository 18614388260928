import { observable, IReactionDisposer } from "mobx";
import { serializable, object, list } from "serializr";
import dayjs from "dayjs";

import { Month } from "./Month";
import { _LastChange } from "./_LastChange";
import { _Dirty } from "./_Dirty";
import { changeTracker } from "../Helpers/changeTracker";

export class Months {

    @observable
    @serializable(list(object(Month)))
    private _items: Month[] = [];

    get all() {
        return this._items as readonly Month[];
    }

    @serializable(object(_LastChange))
    lastChanged = new _LastChange(this);

    dirty = new _Dirty(this);



    ensure(date?: Date) {
        if (!date) date = new Date();

        const d = dayjs(date);
        const existingMonth = this._items.find(x => d.isSame(x.date, 'month'));
        if (existingMonth) return existingMonth;

        const newDate = d.startOf('month').toDate()
        const newMonth = new Month();
        newMonth.date = newDate;
        this._items.push(newMonth);
        console.log('CREATED MONTH');
        return newMonth;
    }

    insert(month: Month) {
        this._items.push(month);
    }

    syncFromRemote(remote: Months) {

        // TODO:
        // - Sync indexes first
        // - Sync only months with changed index last change dates

        const own = this.all;
        const other = remote.all;
        let hasChangedLocally = false;

        // TODO: check removed months on both ends...

        // Check months existing on both sides => sync transactions
        own.forEach(ownMonth => {
            const otherMonth = other.find(x => x.date.getTime() === ownMonth.date.getTime());
            if (!otherMonth) return;

            hasChangedLocally = ownMonth.syncFromRemote(otherMonth) || hasChangedLocally;
        });

        // Check where other has new items
        other.forEach(month => {
            if (!own.some(x => x.date.getTime() === month.date.getTime())) {
                // Is new month
                this.insert(month);
            }
        });

        // Check where own has new items
        own.forEach(month => {
            if (!other.some(x => x.date.getTime() === month.date.getTime())) {
                // Already in own -> nothing to do but detect change
                hasChangedLocally = true;
            }
        })

        return hasChangedLocally;
    }


    getTransactionsRequiredToSync(remote: Months) {

        const own = this.all;
        const other = remote.all;
        const transactionsRequiredToSync: number[] = [];

        own.forEach(ownMonth => {
            const otherMonth = other.find(x => x.date.getTime() === ownMonth.date.getTime());
            if (!otherMonth) return;

            if (otherMonth.transactionsChanged !== ownMonth.transactionsChanged) {
                transactionsRequiredToSync.push(ownMonth.date.getTime());
            }
        });

        return transactionsRequiredToSync;
    }






    constructor() {
        changeTracker(this);
    }

    unsubscriber?: IReactionDisposer;

    public dispose() {
        this.unsubscriber && this.unsubscriber();
    }

    public changeDetection() {
        return [this._items?.length];
    }


}