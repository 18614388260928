import { observable, IReactionDisposer, reaction } from "mobx";
import { serializable, object, list, primitive } from "serializr";

import { Category } from "./Category";
import { _Dirty } from "./_Dirty";
import { _LastChange } from "./_LastChange";
import { changeTracker } from "../Helpers/changeTracker";

export class Categories {

    @observable
    @serializable(list(object(Category)))
    private _items: Category[] = [];

    @serializable(object(_LastChange))
    lastChanged = new _LastChange(this);

    dirty = new _Dirty(this);

    constructor() {
        changeTracker(this);
    }
    
    unsubscriber?: IReactionDisposer;

    public dispose() {
        this.unsubscriber && this.unsubscriber();
    }

    public changeDetection() {
        return [this._items?.length];
    }




    get all() {

        if (this._items.length === 0) {
            console.log('INIT NEW CATEGORIES');
            // Default Categories
            /*
            this._items.push(
                new Category('Car', 'car'),
                new Category('Groceries', 'basket'),
                new Category('Clothing', 'shirt'),
                new Category('Hobby', 'palette'),
                new Category('Food', 'fastfood'),
                new Category('Drinks', 'pint'),
                new Category('Entertainment', 'film'),
                new Category('Travelling', 'airplane'),
                new Category('Sports', 'americanFootball'),
                new Category('Health', 'bandage'),
                new Category('Home', 'home'),
                new Category('Learning', 'school'),
                new Category('Insurance', 'shieldCheckmark'),
                new Category('Saving', 'wallet'),
                new Category('Taxes & Fees', 'trendingDown'),
                new Category('Other', 'help')
            );
            */
        }

        return this._items as readonly Category[];
    }

    get(id: string) {
        return this._items.find(x => x.uid === id);
    }

    create() {
        const newCategory = new Category();
        newCategory.text = '[New]';
        this._items.push(newCategory);
        return newCategory;
    }

    insert(category: Category) {
        this._items.push(category);
    }

    move(from: number, to: number) {
        const elem = this._items.splice(from, 1)[0];
        this._items.splice(to, 0, elem);
    }

    remove(category?: Category) {
        if (!category) return;

        // TODO: Remove linked transactions...

        const idx = this._items.indexOf(category);
        if (idx >= 0) {
            this._removedItems.push(this._items[idx].uid);
            this._items[idx].dispose();
            this._items.splice(idx, 1);
        }
    }

    @serializable(list(primitive()))
    private _removedItems: string[] = [];

    public get removedItems() {
        return this._removedItems as readonly string[];
    }


    /** Synchronizes other categories into this,
     *  returns true, if anything has changed on local side => needs to upload */
    syncFromRemote(remote: Categories) {
        const own = this.all;
        const other = remote.all;
        let hasChangedLocally = false;

        // TODO: check removed categories on both ends...

        // Check categories existing on both sides => last change date for latest
        own.forEach(ownCategory => {
            const otherCategory = other.find(x => x.uid === ownCategory.uid);
            if (!otherCategory) return;

            // Other is newer -> sync to own
            if (ownCategory.lastChanged.get() < otherCategory.lastChanged.get()) {
                ownCategory.applyFrom(otherCategory);
                return;
            }

            // Local is newer => note change
            if (ownCategory.lastChanged.get() > otherCategory.lastChanged.get()) {
                hasChangedLocally = true;
                return;
            }
        });

        // Check where other has new items
        other.forEach(category => {
            if (!own.some(x => x.uid === category.uid)) {
                // Is new category
                this.insert(category);
            }
        });

        // Check where own has new items
        own.forEach(category => {
            if (!other.some(x => x.uid === category.uid)) {
                // Already in own -> nothing to do but detect change
                hasChangedLocally = true;
            }
        })

        return hasChangedLocally;
    }

}
