
import React from 'react'
import { IonInput } from '@ionic/react'
import { OnlyNumberOrUndefinedProps } from '../Types/KeyOfFilter'
import { numberInputParser } from '../Helpers/numberInputParser';
import { observer } from 'mobx-react-lite';

interface Props<T> {
    model: T;
    property: OnlyNumberOrUndefinedProps<T>;
    placeholder?: string;
    onKeyUp?(e: React.KeyboardEvent<HTMLIonInputElement>): void;
    autofocus?: boolean;
    inputRef?: React.RefObject<HTMLIonInputElement> | null;
}

const CurrencyBox = observer(<T extends any>(props: Props<T>) => {

    const value: number = props.model[props.property];

    const handleChange = (value: string | null | undefined) => {
        const num = numberInputParser(value);
        if (typeof num === 'undefined')
            props.model[props.property] = undefined;
        else if (!isNaN(num))
            props.model[props.property] = num;
        else
            props.model[props.property] = props.model[props.property];
    }

    return (
        <IonInput type="number" step="0.01"
            autofocus={props.autofocus}
            ref={props.inputRef}
            placeholder={props.placeholder}
            value={value}
            onIonChange={e => handleChange(e.detail.value)}
            onKeyUp={props.onKeyUp}
        />
    )
})

export default CurrencyBox

