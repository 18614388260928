
/*
Input data from:
https://datahub.io/core/country-codes

Filter only countries with valid language definition
var newCountries = Countries.filter(x => !!x.Languages)

Filter only countries with valid currency
var newCountries = newCountries.filter(x => !!x['ISO4217-currency_alphabetic_code'])

Create dataset
var newCountries = newCountries.map(x => {  return {name: x.official_name_en, currency: x['ISO4217-currency_alphabetic_code'].split(',')[0], language: x.Languages.split(',')[0].split('-')[0] + '-' + x['ISO3166-1-Alpha-2'], region: x['Region Name']} })

Sort dataset
newCountries.sort((a,b) => (a.region.localeCompare(b.region)) || (a.name.localeCompare(b.name)))

*/


export const Countries = [
    {
        "name": "Algeria",
        "currency": "DZD",
        "language": "ar-DZ",
        "region": "Africa"
    },
    {
        "name": "Angola",
        "currency": "AOA",
        "language": "pt-AO",
        "region": "Africa"
    },
    {
        "name": "Benin",
        "currency": "XOF",
        "language": "fr-BJ",
        "region": "Africa"
    },
    {
        "name": "Botswana",
        "currency": "BWP",
        "language": "en-BW",
        "region": "Africa"
    },
    {
        "name": "British Indian Ocean Territory",
        "currency": "USD",
        "language": "en-IO",
        "region": "Africa"
    },
    {
        "name": "Burkina Faso",
        "currency": "XOF",
        "language": "fr-BF",
        "region": "Africa"
    },
    {
        "name": "Burundi",
        "currency": "BIF",
        "language": "fr-BI",
        "region": "Africa"
    },
    {
        "name": "Cabo Verde",
        "currency": "CVE",
        "language": "pt-CV",
        "region": "Africa"
    },
    {
        "name": "Cameroon",
        "currency": "XAF",
        "language": "en-CM",
        "region": "Africa"
    },
    {
        "name": "Central African Republic",
        "currency": "XAF",
        "language": "fr-CF",
        "region": "Africa"
    },
    {
        "name": "Chad",
        "currency": "XAF",
        "language": "fr-TD",
        "region": "Africa"
    },
    {
        "name": "Comoros",
        "currency": "KMF",
        "language": "ar-KM",
        "region": "Africa"
    },
    {
        "name": "Congo",
        "currency": "XAF",
        "language": "fr-CG",
        "region": "Africa"
    },
    {
        "name": "Côte d'Ivoire",
        "currency": "XOF",
        "language": "fr-CI",
        "region": "Africa"
    },
    {
        "name": "Democratic Republic of the Congo",
        "currency": "CDF",
        "language": "fr-CD",
        "region": "Africa"
    },
    {
        "name": "Djibouti",
        "currency": "DJF",
        "language": "fr-DJ",
        "region": "Africa"
    },
    {
        "name": "Egypt",
        "currency": "EGP",
        "language": "ar-EG",
        "region": "Africa"
    },
    {
        "name": "Equatorial Guinea",
        "currency": "XAF",
        "language": "es-GQ",
        "region": "Africa"
    },
    {
        "name": "Eritrea",
        "currency": "ERN",
        "language": "aa-ER",
        "region": "Africa"
    },
    {
        "name": "Ethiopia",
        "currency": "ETB",
        "language": "am-ET",
        "region": "Africa"
    },
    {
        "name": "French Southern Territories",
        "currency": "EUR",
        "language": "fr-TF",
        "region": "Africa"
    },
    {
        "name": "Gabon",
        "currency": "XAF",
        "language": "fr-GA",
        "region": "Africa"
    },
    {
        "name": "Gambia",
        "currency": "GMD",
        "language": "en-GM",
        "region": "Africa"
    },
    {
        "name": "Ghana",
        "currency": "GHS",
        "language": "en-GH",
        "region": "Africa"
    },
    {
        "name": "Guinea",
        "currency": "GNF",
        "language": "fr-GN",
        "region": "Africa"
    },
    {
        "name": "Guinea-Bissau",
        "currency": "XOF",
        "language": "pt-GW",
        "region": "Africa"
    },
    {
        "name": "Kenya",
        "currency": "KES",
        "language": "en-KE",
        "region": "Africa"
    },
    {
        "name": "Lesotho",
        "currency": "LSL",
        "language": "en-LS",
        "region": "Africa"
    },
    {
        "name": "Liberia",
        "currency": "LRD",
        "language": "en-LR",
        "region": "Africa"
    },
    {
        "name": "Libya",
        "currency": "LYD",
        "language": "ar-LY",
        "region": "Africa"
    },
    {
        "name": "Madagascar",
        "currency": "MGA",
        "language": "fr-MG",
        "region": "Africa"
    },
    {
        "name": "Malawi",
        "currency": "MWK",
        "language": "ny-MW",
        "region": "Africa"
    },
    {
        "name": "Mali",
        "currency": "XOF",
        "language": "fr-ML",
        "region": "Africa"
    },
    {
        "name": "Mauritania",
        "currency": "MRO",
        "language": "ar-MR",
        "region": "Africa"
    },
    {
        "name": "Mauritius",
        "currency": "MUR",
        "language": "en-MU",
        "region": "Africa"
    },
    {
        "name": "Mayotte",
        "currency": "EUR",
        "language": "fr-YT",
        "region": "Africa"
    },
    {
        "name": "Morocco",
        "currency": "MAD",
        "language": "ar-MA",
        "region": "Africa"
    },
    {
        "name": "Mozambique",
        "currency": "MZN",
        "language": "pt-MZ",
        "region": "Africa"
    },
    {
        "name": "Namibia",
        "currency": "NAD",
        "language": "en-NA",
        "region": "Africa"
    },
    {
        "name": "Niger",
        "currency": "XOF",
        "language": "fr-NE",
        "region": "Africa"
    },
    {
        "name": "Nigeria",
        "currency": "NGN",
        "language": "en-NG",
        "region": "Africa"
    },
    {
        "name": "Réunion",
        "currency": "EUR",
        "language": "fr-RE",
        "region": "Africa"
    },
    {
        "name": "Rwanda",
        "currency": "RWF",
        "language": "rw-RW",
        "region": "Africa"
    },
    {
        "name": "Saint Helena",
        "currency": "SHP",
        "language": "en-SH",
        "region": "Africa"
    },
    {
        "name": "Sao Tome and Principe",
        "currency": "STD",
        "language": "pt-ST",
        "region": "Africa"
    },
    {
        "name": "Senegal",
        "currency": "XOF",
        "language": "fr-SN",
        "region": "Africa"
    },
    {
        "name": "Seychelles",
        "currency": "SCR",
        "language": "en-SC",
        "region": "Africa"
    },
    {
        "name": "Sierra Leone",
        "currency": "SLL",
        "language": "en-SL",
        "region": "Africa"
    },
    {
        "name": "Somalia",
        "currency": "SOS",
        "language": "so-SO",
        "region": "Africa"
    },
    {
        "name": "South Africa",
        "currency": "ZAR",
        "language": "zu-ZA",
        "region": "Africa"
    },
    {
        "name": "South Sudan",
        "currency": "SSP",
        "language": "en-SS",
        "region": "Africa"
    },
    {
        "name": "Sudan",
        "currency": "SDG",
        "language": "ar-SD",
        "region": "Africa"
    },
    {
        "name": "Swaziland",
        "currency": "SZL",
        "language": "en-SZ",
        "region": "Africa"
    },
    {
        "name": "Togo",
        "currency": "XOF",
        "language": "fr-TG",
        "region": "Africa"
    },
    {
        "name": "Tunisia",
        "currency": "TND",
        "language": "ar-TN",
        "region": "Africa"
    },
    {
        "name": "Uganda",
        "currency": "UGX",
        "language": "en-UG",
        "region": "Africa"
    },
    {
        "name": "United Republic of Tanzania",
        "currency": "TZS",
        "language": "sw-TZ",
        "region": "Africa"
    },
    {
        "name": "Western Sahara",
        "currency": "MAD",
        "language": "ar-EH",
        "region": "Africa"
    },
    {
        "name": "Zambia",
        "currency": "ZMW",
        "language": "en-ZM",
        "region": "Africa"
    },
    {
        "name": "Zimbabwe",
        "currency": "ZWL",
        "language": "en-ZW",
        "region": "Africa"
    },
    {
        "name": "Anguilla",
        "currency": "XCD",
        "language": "en-AI",
        "region": "Americas"
    },
    {
        "name": "Antigua and Barbuda",
        "currency": "XCD",
        "language": "en-AG",
        "region": "Americas"
    },
    {
        "name": "Argentina",
        "currency": "ARS",
        "language": "es-AR",
        "region": "Americas"
    },
    {
        "name": "Aruba",
        "currency": "AWG",
        "language": "nl-AW",
        "region": "Americas"
    },
    {
        "name": "Bahamas",
        "currency": "BSD",
        "language": "en-BS",
        "region": "Americas"
    },
    {
        "name": "Barbados",
        "currency": "BBD",
        "language": "en-BB",
        "region": "Americas"
    },
    {
        "name": "Belize",
        "currency": "BZD",
        "language": "en-BZ",
        "region": "Americas"
    },
    {
        "name": "Bermuda",
        "currency": "BMD",
        "language": "en-BM",
        "region": "Americas"
    },
    {
        "name": "Bolivia (Plurinational State of)",
        "currency": "BOB",
        "language": "es-BO",
        "region": "Americas"
    },
    {
        "name": "Bonaire, Sint Eustatius and Saba",
        "currency": "USD",
        "language": "nl-BQ",
        "region": "Americas"
    },
    {
        "name": "Brazil",
        "currency": "BRL",
        "language": "pt-BR",
        "region": "Americas"
    },
    {
        "name": "British Virgin Islands",
        "currency": "USD",
        "language": "en-VG",
        "region": "Americas"
    },
    {
        "name": "Canada",
        "currency": "CAD",
        "language": "en-CA",
        "region": "Americas"
    },
    {
        "name": "Cayman Islands",
        "currency": "KYD",
        "language": "en-KY",
        "region": "Americas"
    },
    {
        "name": "Chile",
        "currency": "CLP",
        "language": "es-CL",
        "region": "Americas"
    },
    {
        "name": "Colombia",
        "currency": "COP",
        "language": "es-CO",
        "region": "Americas"
    },
    {
        "name": "Costa Rica",
        "currency": "CRC",
        "language": "es-CR",
        "region": "Americas"
    },
    {
        "name": "Cuba",
        "currency": "CUP",
        "language": "es-CU",
        "region": "Americas"
    },
    {
        "name": "Curaçao",
        "currency": "ANG",
        "language": "nl-CW",
        "region": "Americas"
    },
    {
        "name": "Dominica",
        "currency": "XCD",
        "language": "en-DM",
        "region": "Americas"
    },
    {
        "name": "Dominican Republic",
        "currency": "DOP",
        "language": "es-DO",
        "region": "Americas"
    },
    {
        "name": "Ecuador",
        "currency": "USD",
        "language": "es-EC",
        "region": "Americas"
    },
    {
        "name": "El Salvador",
        "currency": "SVC",
        "language": "es-SV",
        "region": "Americas"
    },
    {
        "name": "French Guiana",
        "currency": "EUR",
        "language": "fr-GF",
        "region": "Americas"
    },
    {
        "name": "Greenland",
        "currency": "DKK",
        "language": "kl-GL",
        "region": "Americas"
    },
    {
        "name": "Grenada",
        "currency": "XCD",
        "language": "en-GD",
        "region": "Americas"
    },
    {
        "name": "Guadeloupe",
        "currency": "EUR",
        "language": "fr-GP",
        "region": "Americas"
    },
    {
        "name": "Guatemala",
        "currency": "GTQ",
        "language": "es-GT",
        "region": "Americas"
    },
    {
        "name": "Guyana",
        "currency": "GYD",
        "language": "en-GY",
        "region": "Americas"
    },
    {
        "name": "Haiti",
        "currency": "HTG",
        "language": "ht-HT",
        "region": "Americas"
    },
    {
        "name": "Honduras",
        "currency": "HNL",
        "language": "es-HN",
        "region": "Americas"
    },
    {
        "name": "Jamaica",
        "currency": "JMD",
        "language": "en-JM",
        "region": "Americas"
    },
    {
        "name": "Martinique",
        "currency": "EUR",
        "language": "fr-MQ",
        "region": "Americas"
    },
    {
        "name": "Mexico",
        "currency": "MXN",
        "language": "es-MX",
        "region": "Americas"
    },
    {
        "name": "Montserrat",
        "currency": "XCD",
        "language": "en-MS",
        "region": "Americas"
    },
    {
        "name": "Nicaragua",
        "currency": "NIO",
        "language": "es-NI",
        "region": "Americas"
    },
    {
        "name": "Panama",
        "currency": "PAB",
        "language": "es-PA",
        "region": "Americas"
    },
    {
        "name": "Paraguay",
        "currency": "PYG",
        "language": "es-PY",
        "region": "Americas"
    },
    {
        "name": "Peru",
        "currency": "PEN",
        "language": "es-PE",
        "region": "Americas"
    },
    {
        "name": "Puerto Rico",
        "currency": "USD",
        "language": "en-PR",
        "region": "Americas"
    },
    {
        "name": "Saint Barthélemy",
        "currency": "EUR",
        "language": "fr-BL",
        "region": "Americas"
    },
    {
        "name": "Saint Kitts and Nevis",
        "currency": "XCD",
        "language": "en-KN",
        "region": "Americas"
    },
    {
        "name": "Saint Lucia",
        "currency": "XCD",
        "language": "en-LC",
        "region": "Americas"
    },
    {
        "name": "Saint Martin (French Part)",
        "currency": "EUR",
        "language": "fr-MF",
        "region": "Americas"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "currency": "EUR",
        "language": "fr-PM",
        "region": "Americas"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "currency": "XCD",
        "language": "en-VC",
        "region": "Americas"
    },
    {
        "name": "Sint Maarten (Dutch part)",
        "currency": "ANG",
        "language": "nl-SX",
        "region": "Americas"
    },
    {
        "name": "Suriname",
        "currency": "SRD",
        "language": "nl-SR",
        "region": "Americas"
    },
    {
        "name": "Trinidad and Tobago",
        "currency": "TTD",
        "language": "en-TT",
        "region": "Americas"
    },
    {
        "name": "Turks and Caicos Islands",
        "currency": "USD",
        "language": "en-TC",
        "region": "Americas"
    },
    {
        "name": "United States of America",
        "currency": "USD",
        "language": "en-US",
        "region": "Americas"
    },
    {
        "name": "United States Virgin Islands",
        "currency": "USD",
        "language": "en-VI",
        "region": "Americas"
    },
    {
        "name": "Uruguay",
        "currency": "UYU",
        "language": "es-UY",
        "region": "Americas"
    },
    {
        "name": "Venezuela (Bolivarian Republic of)",
        "currency": "VEF",
        "language": "es-VE",
        "region": "Americas"
    },
    {
        "name": "Afghanistan",
        "currency": "AFN",
        "language": "fa-AF",
        "region": "Asia"
    },
    {
        "name": "Armenia",
        "currency": "AMD",
        "language": "hy-AM",
        "region": "Asia"
    },
    {
        "name": "Azerbaijan",
        "currency": "AZN",
        "language": "az-AZ",
        "region": "Asia"
    },
    {
        "name": "Bahrain",
        "currency": "BHD",
        "language": "ar-BH",
        "region": "Asia"
    },
    {
        "name": "Bangladesh",
        "currency": "BDT",
        "language": "bn-BD",
        "region": "Asia"
    },
    {
        "name": "Bhutan",
        "currency": "INR",
        "language": "dz-BT",
        "region": "Asia"
    },
    {
        "name": "Brunei Darussalam",
        "currency": "BND",
        "language": "ms-BN",
        "region": "Asia"
    },
    {
        "name": "Cambodia",
        "currency": "KHR",
        "language": "km-KH",
        "region": "Asia"
    },
    {
        "name": "China",
        "currency": "CNY",
        "language": "zh-CN",
        "region": "Asia"
    },
    {
        "name": "China, Hong Kong Special Administrative Region",
        "currency": "HKD",
        "language": "zh-HK",
        "region": "Asia"
    },
    {
        "name": "China, Macao Special Administrative Region",
        "currency": "MOP",
        "language": "zh-MO",
        "region": "Asia"
    },
    {
        "name": "Cyprus",
        "currency": "EUR",
        "language": "el-CY",
        "region": "Asia"
    },
    {
        "name": "Democratic People's Republic of Korea",
        "currency": "KPW",
        "language": "ko-KP",
        "region": "Asia"
    },
    {
        "name": "Georgia",
        "currency": "GEL",
        "language": "ka-GE",
        "region": "Asia"
    },
    {
        "name": "India",
        "currency": "INR",
        "language": "en-IN",
        "region": "Asia"
    },
    {
        "name": "Indonesia",
        "currency": "IDR",
        "language": "id-ID",
        "region": "Asia"
    },
    {
        "name": "Iran (Islamic Republic of)",
        "currency": "IRR",
        "language": "fa-IR",
        "region": "Asia"
    },
    {
        "name": "Iraq",
        "currency": "IQD",
        "language": "ar-IQ",
        "region": "Asia"
    },
    {
        "name": "Israel",
        "currency": "ILS",
        "language": "he-IL",
        "region": "Asia"
    },
    {
        "name": "Japan",
        "currency": "JPY",
        "language": "ja-JP",
        "region": "Asia"
    },
    {
        "name": "Jordan",
        "currency": "JOD",
        "language": "ar-JO",
        "region": "Asia"
    },
    {
        "name": "Kazakhstan",
        "currency": "KZT",
        "language": "kk-KZ",
        "region": "Asia"
    },
    {
        "name": "Kuwait",
        "currency": "KWD",
        "language": "ar-KW",
        "region": "Asia"
    },
    {
        "name": "Kyrgyzstan",
        "currency": "KGS",
        "language": "ky-KG",
        "region": "Asia"
    },
    {
        "name": "Lao People's Democratic Republic",
        "currency": "LAK",
        "language": "lo-LA",
        "region": "Asia"
    },
    {
        "name": "Lebanon",
        "currency": "LBP",
        "language": "ar-LB",
        "region": "Asia"
    },
    {
        "name": "Malaysia",
        "currency": "MYR",
        "language": "ms-MY",
        "region": "Asia"
    },
    {
        "name": "Maldives",
        "currency": "MVR",
        "language": "dv-MV",
        "region": "Asia"
    },
    {
        "name": "Mongolia",
        "currency": "MNT",
        "language": "mn-MN",
        "region": "Asia"
    },
    {
        "name": "Myanmar",
        "currency": "MMK",
        "language": "my-MM",
        "region": "Asia"
    },
    {
        "name": "Nepal",
        "currency": "NPR",
        "language": "ne-NP",
        "region": "Asia"
    },
    {
        "name": "Oman",
        "currency": "OMR",
        "language": "ar-OM",
        "region": "Asia"
    },
    {
        "name": "Pakistan",
        "currency": "PKR",
        "language": "ur-PK",
        "region": "Asia"
    },
    {
        "name": "Philippines",
        "currency": "PHP",
        "language": "tl-PH",
        "region": "Asia"
    },
    {
        "name": "Qatar",
        "currency": "QAR",
        "language": "ar-QA",
        "region": "Asia"
    },
    {
        "name": "Republic of Korea",
        "currency": "KRW",
        "language": "ko-KR",
        "region": "Asia"
    },
    {
        "name": "Saudi Arabia",
        "currency": "SAR",
        "language": "ar-SA",
        "region": "Asia"
    },
    {
        "name": "Singapore",
        "currency": "SGD",
        "language": "cmn-SG",
        "region": "Asia"
    },
    {
        "name": "Sri Lanka",
        "currency": "LKR",
        "language": "si-LK",
        "region": "Asia"
    },
    {
        "name": "Syrian Arab Republic",
        "currency": "SYP",
        "language": "ar-SY",
        "region": "Asia"
    },
    {
        "name": "Tajikistan",
        "currency": "TJS",
        "language": "tg-TJ",
        "region": "Asia"
    },
    {
        "name": "Thailand",
        "currency": "THB",
        "language": "th-TH",
        "region": "Asia"
    },
    {
        "name": "Timor-Leste",
        "currency": "USD",
        "language": "tet-TL",
        "region": "Asia"
    },
    {
        "name": "Turkey",
        "currency": "TRY",
        "language": "tr-TR",
        "region": "Asia"
    },
    {
        "name": "Turkmenistan",
        "currency": "TMT",
        "language": "tk-TM",
        "region": "Asia"
    },
    {
        "name": "United Arab Emirates",
        "currency": "AED",
        "language": "ar-AE",
        "region": "Asia"
    },
    {
        "name": "Uzbekistan",
        "currency": "UZS",
        "language": "uz-UZ",
        "region": "Asia"
    },
    {
        "name": "Viet Nam",
        "currency": "VND",
        "language": "vi-VN",
        "region": "Asia"
    },
    {
        "name": "Yemen",
        "currency": "YER",
        "language": "ar-YE",
        "region": "Asia"
    },
    {
        "name": "Åland Islands",
        "currency": "EUR",
        "language": "sv-AX",
        "region": "Europe"
    },
    {
        "name": "Albania",
        "currency": "ALL",
        "language": "sq-AL",
        "region": "Europe"
    },
    {
        "name": "Andorra",
        "currency": "EUR",
        "language": "ca-AD",
        "region": "Europe"
    },
    {
        "name": "Austria",
        "currency": "EUR",
        "language": "de-AT",
        "region": "Europe"
    },
    {
        "name": "Belarus",
        "currency": "BYN",
        "language": "be-BY",
        "region": "Europe"
    },
    {
        "name": "Belgium",
        "currency": "EUR",
        "language": "nl-BE",
        "region": "Europe"
    },
    {
        "name": "Bosnia and Herzegovina",
        "currency": "BAM",
        "language": "bs-BA",
        "region": "Europe"
    },
    {
        "name": "Bulgaria",
        "currency": "BGN",
        "language": "bg-BG",
        "region": "Europe"
    },
    {
        "name": "Croatia",
        "currency": "HRK",
        "language": "hr-HR",
        "region": "Europe"
    },
    {
        "name": "Czechia",
        "currency": "CZK",
        "language": "cs-CZ",
        "region": "Europe"
    },
    {
        "name": "Denmark",
        "currency": "DKK",
        "language": "da-DK",
        "region": "Europe"
    },
    {
        "name": "Estonia",
        "currency": "EUR",
        "language": "et-EE",
        "region": "Europe"
    },
    {
        "name": "Faroe Islands",
        "currency": "DKK",
        "language": "fo-FO",
        "region": "Europe"
    },
    {
        "name": "Finland",
        "currency": "EUR",
        "language": "fi-FI",
        "region": "Europe"
    },
    {
        "name": "France",
        "currency": "EUR",
        "language": "fr-FR",
        "region": "Europe"
    },
    {
        "name": "Germany",
        "currency": "EUR",
        "language": "de-DE",
        "region": "Europe"
    },
    {
        "name": "Gibraltar",
        "currency": "GIP",
        "language": "en-GI",
        "region": "Europe"
    },
    {
        "name": "Greece",
        "currency": "EUR",
        "language": "el-GR",
        "region": "Europe"
    },
    {
        "name": "Guernsey",
        "currency": "GBP",
        "language": "en-GG",
        "region": "Europe"
    },
    {
        "name": "Holy See",
        "currency": "EUR",
        "language": "la-VA",
        "region": "Europe"
    },
    {
        "name": "Hungary",
        "currency": "HUF",
        "language": "hu-HU",
        "region": "Europe"
    },
    {
        "name": "Iceland",
        "currency": "ISK",
        "language": "is-IS",
        "region": "Europe"
    },
    {
        "name": "Ireland",
        "currency": "EUR",
        "language": "en-IE",
        "region": "Europe"
    },
    {
        "name": "Isle of Man",
        "currency": "GBP",
        "language": "en-IM",
        "region": "Europe"
    },
    {
        "name": "Italy",
        "currency": "EUR",
        "language": "it-IT",
        "region": "Europe"
    },
    {
        "name": "Jersey",
        "currency": "GBP",
        "language": "en-JE",
        "region": "Europe"
    },
    {
        "name": "Latvia",
        "currency": "EUR",
        "language": "lv-LV",
        "region": "Europe"
    },
    {
        "name": "Liechtenstein",
        "currency": "CHF",
        "language": "de-LI",
        "region": "Europe"
    },
    {
        "name": "Lithuania",
        "currency": "EUR",
        "language": "lt-LT",
        "region": "Europe"
    },
    {
        "name": "Luxembourg",
        "currency": "EUR",
        "language": "lb-LU",
        "region": "Europe"
    },
    {
        "name": "Malta",
        "currency": "EUR",
        "language": "mt-MT",
        "region": "Europe"
    },
    {
        "name": "Monaco",
        "currency": "EUR",
        "language": "fr-MC",
        "region": "Europe"
    },
    {
        "name": "Montenegro",
        "currency": "EUR",
        "language": "sr-ME",
        "region": "Europe"
    },
    {
        "name": "Netherlands",
        "currency": "EUR",
        "language": "nl-NL",
        "region": "Europe"
    },
    {
        "name": "Norway",
        "currency": "NOK",
        "language": "no-NO",
        "region": "Europe"
    },
    {
        "name": "Poland",
        "currency": "PLN",
        "language": "pl-PL",
        "region": "Europe"
    },
    {
        "name": "Portugal",
        "currency": "EUR",
        "language": "pt-PT",
        "region": "Europe"
    },
    {
        "name": "Republic of Moldova",
        "currency": "MDL",
        "language": "ro-MD",
        "region": "Europe"
    },
    {
        "name": "Romania",
        "currency": "RON",
        "language": "ro-RO",
        "region": "Europe"
    },
    {
        "name": "Russian Federation",
        "currency": "RUB",
        "language": "ru-RU",
        "region": "Europe"
    },
    {
        "name": "San Marino",
        "currency": "EUR",
        "language": "it-SM",
        "region": "Europe"
    },
    {
        "name": "Serbia",
        "currency": "RSD",
        "language": "sr-RS",
        "region": "Europe"
    },
    {
        "name": "Slovakia",
        "currency": "EUR",
        "language": "sk-SK",
        "region": "Europe"
    },
    {
        "name": "Slovenia",
        "currency": "EUR",
        "language": "sl-SI",
        "region": "Europe"
    },
    {
        "name": "Spain",
        "currency": "EUR",
        "language": "es-ES",
        "region": "Europe"
    },
    {
        "name": "Svalbard and Jan Mayen Islands",
        "currency": "NOK",
        "language": "no-SJ",
        "region": "Europe"
    },
    {
        "name": "Sweden",
        "currency": "SEK",
        "language": "sv-SE",
        "region": "Europe"
    },
    {
        "name": "Switzerland",
        "currency": "CHF",
        "language": "de-CH",
        "region": "Europe"
    },
    {
        "name": "The former Yugoslav Republic of Macedonia",
        "currency": "MKD",
        "language": "mk-MK",
        "region": "Europe"
    },
    {
        "name": "Ukraine",
        "currency": "UAH",
        "language": "uk-UA",
        "region": "Europe"
    },
    {
        "name": "United Kingdom of Great Britain and Northern Ireland",
        "currency": "GBP",
        "language": "en-GB",
        "region": "Europe"
    },
    {
        "name": "American Samoa",
        "currency": "USD",
        "language": "en-AS",
        "region": "Oceania"
    },
    {
        "name": "Australia",
        "currency": "AUD",
        "language": "en-AU",
        "region": "Oceania"
    },
    {
        "name": "Christmas Island",
        "currency": "AUD",
        "language": "en-CX",
        "region": "Oceania"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "currency": "AUD",
        "language": "ms-CC",
        "region": "Oceania"
    },
    {
        "name": "Cook Islands",
        "currency": "NZD",
        "language": "en-CK",
        "region": "Oceania"
    },
    {
        "name": "Fiji",
        "currency": "FJD",
        "language": "en-FJ",
        "region": "Oceania"
    },
    {
        "name": "French Polynesia",
        "currency": "XPF",
        "language": "fr-PF",
        "region": "Oceania"
    },
    {
        "name": "Guam",
        "currency": "USD",
        "language": "en-GU",
        "region": "Oceania"
    },
    {
        "name": "Kiribati",
        "currency": "AUD",
        "language": "en-KI",
        "region": "Oceania"
    },
    {
        "name": "Marshall Islands",
        "currency": "USD",
        "language": "mh-MH",
        "region": "Oceania"
    },
    {
        "name": "Micronesia (Federated States of)",
        "currency": "USD",
        "language": "en-FM",
        "region": "Oceania"
    },
    {
        "name": "Nauru",
        "currency": "AUD",
        "language": "na-NR",
        "region": "Oceania"
    },
    {
        "name": "New Caledonia",
        "currency": "XPF",
        "language": "fr-NC",
        "region": "Oceania"
    },
    {
        "name": "New Zealand",
        "currency": "NZD",
        "language": "en-NZ",
        "region": "Oceania"
    },
    {
        "name": "Niue",
        "currency": "NZD",
        "language": "niu-NU",
        "region": "Oceania"
    },
    {
        "name": "Norfolk Island",
        "currency": "AUD",
        "language": "en-NF",
        "region": "Oceania"
    },
    {
        "name": "Northern Mariana Islands",
        "currency": "USD",
        "language": "fil-MP",
        "region": "Oceania"
    },
    {
        "name": "Palau",
        "currency": "USD",
        "language": "pau-PW",
        "region": "Oceania"
    },
    {
        "name": "Papua New Guinea",
        "currency": "PGK",
        "language": "en-PG",
        "region": "Oceania"
    },
    {
        "name": "Pitcairn",
        "currency": "NZD",
        "language": "en-PN",
        "region": "Oceania"
    },
    {
        "name": "Samoa",
        "currency": "WST",
        "language": "sm-WS",
        "region": "Oceania"
    },
    {
        "name": "Solomon Islands",
        "currency": "SBD",
        "language": "en-SB",
        "region": "Oceania"
    },
    {
        "name": "Tokelau",
        "currency": "NZD",
        "language": "tkl-TK",
        "region": "Oceania"
    },
    {
        "name": "Tonga",
        "currency": "TOP",
        "language": "to-TO",
        "region": "Oceania"
    },
    {
        "name": "Tuvalu",
        "currency": "AUD",
        "language": "tvl-TV",
        "region": "Oceania"
    },
    {
        "name": "United States Minor Outlying Islands",
        "currency": "USD",
        "language": "en-UM",
        "region": "Oceania"
    },
    {
        "name": "Vanuatu",
        "currency": "VUV",
        "language": "bi-VU",
        "region": "Oceania"
    },
    {
        "name": "Wallis and Futuna Islands",
        "currency": "XPF",
        "language": "wls-WF",
        "region": "Oceania"
    }
]