import React from 'react'
import { IonList, IonItem, IonLabel } from '@ionic/react'
import TextBox from '../../General/Components/TextBox'
import { Category } from '../../Data/Model/Category'
import IconPicker from './IconPicker'
import { TIcon } from '../../Data/Constants/Icons'
import { observer } from 'mobx-react-lite'

interface Props {
    category: Category;
}

const CategoryForm = observer((props: Props) => {

    const handleIconChange = (icon: TIcon) => {
        props.category.icon = icon;
    }

    return (
        <IonList>
            <IonItem>
                <IonLabel position='floating'>Name</IonLabel>
                <TextBox model={props.category} property='text' autofocus />
            </IonItem>
            <IonItem>
                <IonLabel position='floating'>Available Tags</IonLabel>
                <TextBox model={props.category} property='availableTags' />
            </IonItem>
            <IonItem>
                <IonLabel position='stacked'>Icon</IonLabel>
                <IconPicker selectedIcon={props.category.icon} onChange={handleIconChange} />
            </IonItem>
        </IonList>
    )
})

export default CategoryForm
