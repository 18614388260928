
import React from 'react'
import { IonInput } from '@ionic/react'
import { OnlyStringOrUndefinedProps } from '../Types/KeyOfFilter'
import { observer } from 'mobx-react-lite';

interface Props<T> {
    model: T;
    property: OnlyStringOrUndefinedProps<T>;
    placeholder?: string;
    onKeyUp?(e: React.KeyboardEvent<HTMLIonInputElement>): void;
    autofocus?: boolean;
    inputRef?: React.RefObject<HTMLIonInputElement> | null;
}

const TextBox = observer(<T extends any>(props: Props<T>) => {

    const value: string = props.model[props.property];

    const handleChange = (value: string | null | undefined) => {
        if (!value)
            props.model[props.property] = undefined;
        else
            props.model[props.property] = value;
    }

    return (
        <IonInput type="text"
            autofocus={props.autofocus}
            ref={props.inputRef}
            placeholder={props.placeholder}
            value={value}
            onIonChange={e => handleChange(e.detail.value)}
            onKeyUp={props.onKeyUp}
        />
    )
})

export default TextBox

