import { IDataProvider } from "./IDataProvider";

const LOCAL_STORAGE_PROPERTY = 'lag3_store';

const LOCAL_STORAGE_CONFIG_PROPERTY = 'stor_config';
const LOCAL_STORAGE_SYNC_CONFIG_PROPERTY = 'stor_syncconfig';
const LOCAL_STORAGE_CATEGORIES_PROPERTY = 'stor_categories';
const LOCAL_STORAGE_MONTHS_PROPERTY = 'stor_months';
const LOCAL_STORAGE_TRANSACTION_PROPERTY_PREFIX = 'stor_transactions_';

export class LocalStorageDataProvider implements IDataProvider {
    
    
    
    async loadConfig(): Promise<any> {
        const storedItem = window.localStorage.getItem(LOCAL_STORAGE_CONFIG_PROPERTY);
        if (!storedItem) return undefined;
        return JSON.parse(storedItem);
    }
    async saveConfig(data: any): Promise<boolean> {
        window.localStorage.setItem(LOCAL_STORAGE_CONFIG_PROPERTY, JSON.stringify(data));
        return true;
    }

    async loadSyncConfig(): Promise<any> {
        const storedItem = window.localStorage.getItem(LOCAL_STORAGE_SYNC_CONFIG_PROPERTY);
        if (!storedItem) return undefined;
        return JSON.parse(storedItem);
    }
    async saveSyncConfig(data: any): Promise<boolean> {
        window.localStorage.setItem(LOCAL_STORAGE_SYNC_CONFIG_PROPERTY, JSON.stringify(data));
        return true;
    }

    async loadCategories(): Promise<any> {
        const storedItem = window.localStorage.getItem(LOCAL_STORAGE_CATEGORIES_PROPERTY);
        if (!storedItem) return undefined;
        return JSON.parse(storedItem);
    }
    async saveCategories(data: any): Promise<boolean> {
        window.localStorage.setItem(LOCAL_STORAGE_CATEGORIES_PROPERTY, JSON.stringify(data));
        return true;
    }

    async loadMonths(): Promise<any> {
        const storedItem = window.localStorage.getItem(LOCAL_STORAGE_MONTHS_PROPERTY);
        if (!storedItem) return undefined;
        return JSON.parse(storedItem);
    }
    async saveMonths(data: any): Promise<boolean> {
        window.localStorage.setItem(LOCAL_STORAGE_MONTHS_PROPERTY, JSON.stringify(data));
        return true;
    }

    loadTransactions(timestamp: number): any {
        const storedItem = window.localStorage.getItem(LOCAL_STORAGE_TRANSACTION_PROPERTY_PREFIX + timestamp);
        if (!storedItem) return undefined;
        return JSON.parse(storedItem);
    }
    async saveTransactions(timestamp: number, data: any): Promise<boolean> {
        window.localStorage.setItem(LOCAL_STORAGE_TRANSACTION_PROPERTY_PREFIX + timestamp, JSON.stringify(data));
        return true;
    }

    async save(data: any) {
        window.localStorage.setItem(LOCAL_STORAGE_PROPERTY, JSON.stringify(data));
        return true;
    }

    async load() {
        const storedItem = window.localStorage.getItem(LOCAL_STORAGE_PROPERTY);
        if (!storedItem) return undefined;

        const storedObj = JSON.parse(storedItem);
        return storedObj;
    }

    async createBackup() {
        const storedItem = window.localStorage.getItem(LOCAL_STORAGE_PROPERTY);
        if (!storedItem) return;

        const backupKey = `store-backup-${Date.now()}`;
        window.localStorage.setItem(backupKey, storedItem);
    }


}
