import { serialize, deserialize } from "serializr";

import { Categories } from "../Model/Categories";
import { Months } from "../Model/Months";
import { Transactions } from "../Model/Transactions";
import { Configuration } from "../Model/Configuration";
import { SyncConfig } from "../Model/SyncConfig";


export class Serializer {

    serializeConfig(data: Configuration) {
        return serialize(Configuration, data);
    }

    deserializeConfig(serialized: any) {
        if (!serialized) return undefined;
        const data = deserialize(Configuration, serialized);
        return data ?? undefined;
    }


    serializeSyncConfig(data: SyncConfig) {
        return serialize(SyncConfig, data);
    }

    deserializeSyncConfig(serialized: any) {
        if (!serialized) return undefined;
        const data = deserialize(SyncConfig, serialized);
        return data ?? undefined;
    }


    serializeCategories(data: Categories) {
        return serialize(Categories, data);
    }

    deserializeCategories(serialized: any) {
        if (!serialized) return undefined;
        const data = deserialize(Categories, serialized);
        return data ?? undefined;
    }

    serializeMonths(data: Months) {
        return serialize(Months, data);
    }

    deserializeMonths(serialized: any) {
        if (!serialized) return undefined;
        const data = deserialize(Months, serialized);
        return data ?? undefined;
    }

    serializeTransactions(data: Transactions) {
        return serialize(Transactions, data);
    }

    deserializeTransactions(serialized: any) {
        if (!serialized) return undefined;
        const data = deserialize(Transactions, serialized);
        return data ?? undefined;
    }

}
