import { Store } from "../Store/Store";
import { Transaction } from "../Model/Transaction";

export class Actions {

    constructor(private store: Store) { }

    addTransaction(transaction: Transaction) {
        transaction.date = new Date(Date.now());
        const month = this.store.data.months.ensure(transaction.date);
        month.transactions.insert(transaction);
    }

}