import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'mobx-react-lite/batchingForReactDom'

// Dayjs Setup
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { IonReactRouter } from '@ionic/react-router';
import { StoreProvider } from './Features/Data/Context/StoreContext';

dayjs.extend(isBetween);

ReactDOM.render(
    (<StoreProvider>
        <IonReactRouter>
            <App />
        </IonReactRouter>
    </StoreProvider>),
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
