import React from 'react'
import { observer } from 'mobx-react-lite';
import { IonGrid, IonRow } from '@ionic/react'
import CategoryGridItem from './CategoryGridItem'
import { useStore } from '../../Data/Context/StoreContext';
import CategoryHelper from './CategoryHelper';

interface Props {

}

const CategoryGrid: React.FC<Props> = observer(() => {

    const store = useStore();

    const isEmpty = store.data.categories.all.length === 0;

    return (
        <IonGrid className='ion-text-center'>
            <IonRow className='ion-justify-content-center'>

                {isEmpty && <CategoryHelper />}

                {!isEmpty && store.data.categories.all.map(category => (
                    <CategoryGridItem
                        key={category.uid}
                        category={category}
                    />
                ))}
            </IonRow>
        </IonGrid>

    )
});

export default CategoryGrid
